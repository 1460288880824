import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { all, identity, join, reject, isNil, isEmpty } from 'ramda'

import { pickDeepDefined } from '../../helpers'

import { sliceSelector as clientsSliceSelector } from '../../clients/state/clients-selectors'
import { fetch as fetchClients } from '../../clients/state/clients-reducer'
import { itemSelector } from '../state/clients-selectors'
import { update } from '../state/clients-reducer'

import Layout from '../../components/layout'
import ClientForm from '../components/client-form'

const EditClientScreen = (props) => {
  const { match: { params: { clientId } } } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { all: { isFetching: isClientsFetching, isCached: isClientsCached, error: clientsError } } = useSelector(clientsSliceSelector)
  const client = useSelector(itemSelector(clientId))

  useEffect(() => {
    if (!isClientsCached && !isClientsFetching) dispatch(fetchClients())
  }, [ dispatch, isClientsCached, isClientsFetching ])

  const isFetching = all(identity, [ isClientsFetching ])
  const isCached = all(identity, [ isClientsCached ])
  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ clientsError, globalError ]))

  const [ formData, setFormData ] = useState({})

  const handleSubmit = () => {
    dispatch(update(clientId, pickDeepDefined(formData), () => history.push('/clients'), setGlobalError))
  }
  return (
    <Layout>
      <h3>Modifier le client</h3>

      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && !isFetching &&
        <ClientForm { ...{ initialFormData: client, setFormData } } />
      }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" disabled={ isFetching } onClick={ handleSubmit }>Modifier</button>
      </div>
    </Layout>
  )
}

export default EditClientScreen
