import { path, reject, compose, map, fromPairs, split, isEmpty, isNil, either, equals, test, reduce, append, replace, keys } from 'ramda'

export const isEmptyOrNil = either(isEmpty, isNil)

export const mapIdToObj = (list) => reduce((acc, item) => {
  acc[item._id] = item
  return acc
}, {}, list)

export const DATETIME_FORMAT = 'DD-MM-YYYY [à] HH:mm'
export const DATE_FORMAT = 'DD-MM-YYYY'

export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mmZ'
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

const currencyFormatter = new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' })
export const formatCurrency = currencyFormatter.format

const isEntityKey = either(test(/Id$/), equals('createdBy'))

const entityStateKeys = {
  clientId: 'clients',
  venueId: 'venues',
  createdBy: 'users',
  employeeId: 'users',
  swapEmployeeId: 'users',
}

export const denormalizeEntity = (state, item) => {
  return reduce((innerAcc, key) => {
    const val = item[key]

    if (isEntityKey(key)) {
      const entity = entityStateKeys[key]
      if (!entity) return val
      const idMap = state[entity].byId
      const denormalizedKey = `_${replace('Id', '', key)}`

      innerAcc[denormalizedKey] = idMap[val] || {}
    }

    innerAcc[key] = val
    return innerAcc
  }, {}, keys(item))
}

export const denormalizeEntities = (state, list) => reduce((acc, item) => {
  const denormalized = denormalizeEntity(state, item)
  return append(denormalized, acc)
}, [], list)

export const pickDeepDefined = (item) => reduce((acc, key) => {
  const val = item[key]
  if (isEmptyOrNil(val)) {
    return acc
  }

  if (Array.isArray(val)) {
    acc[key] = map((v) => {
      return typeof v === 'object'
        ? pickDeepDefined(v)
        : v
    }, reject(isEmptyOrNil, val))
    return acc
  }

  if (typeof val === 'object') {
    acc[key] = pickDeepDefined(val)
    return acc
  }

  acc[key] = val
  return acc
}, {}, keys(item))

export const translateSearch = (searchString) => {
  return compose(fromPairs, map(split('=')), split('&'), replace('?', ''))(searchString)
}

export const summarizeProp = (p, list) => reduce((acc, item) => {
  const value = Number(path(p, item))
  if (!value) return acc
  return acc + value
}, 0, list)

