import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { defaultTo, filter, isNil } from 'ramda'

import Layout from '../../components/layout'
import BookingsTable from '../components/bookings-table'
import BookingsCalendar from '../components/bookings-calendar'
import DateRangePicker from '../../components/date-range-picker'

import { sliceSelector, allSelector } from '../state/bookings-selectors'
import { fetch } from '../state/bookings-reducer'
import { SERVER_DATE_FORMAT } from '../../helpers'

const BookingsScreen = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { location: { state: { viewType = 'calendar' } } } = props

  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const allBookings = useSelector(allSelector)

  const [ bookings, setBookings ] = useState([])

  const defaultDateRange = {
    startDate: moment().startOf('month').format(SERVER_DATE_FORMAT),
    endDate: moment().endOf('month').format(SERVER_DATE_FORMAT),
  }
  const [ dateRange, setDateRange ] = useState(defaultDateRange)

  useEffect(() => {
    const { location: { state } } = props
    setDateRange({
      startDate: defaultTo(defaultDateRange.startDate, state?.startDate),
      endDate: defaultTo(defaultDateRange.endDate, state?.endDate),
    })
  }, [ props.location.state ])

  const handleChangeDateRange = (newDateRange) => {
    const { match: { path } } = props
    history.push({
      pathname: path,
      state: {
        ...newDateRange,
        viewType,
      },
    })
  }

  useEffect(() => {
    if (!isCached) dispatch(fetch())
  }, [ dispatch, isCached ])

  useEffect(() => {
    const filteredBookings = filter(({ start }) => {
      return moment(start).isBetween(
        moment(dateRange.startDate).startOf('day'),
        moment(dateRange.endDate).endOf('day')
      )
    }, allBookings)
    setBookings(filteredBookings)
  }, [ allBookings, dateRange ])

  const handleClickCreate = () => {
    history.push('/bookings/new')
  }
  return (
    <Layout>
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-bottom">
        <div>
          <h3 className="uk-margin-remove-bottom">
            Réservations
          </h3>

          { viewType === 'list' &&
            <DateRangePicker dateRange={ dateRange } onChange={ handleChangeDateRange } />
          }
        </div>
        <div className="uk-flex uk-flex-middle">
          <button className="uk-button uk-button-primary uk-margin-medium-left" onClick={ handleClickCreate }>Créer</button>
        </div>
      </div>

      { isFetching && <div data-uk-spinner></div> }
      { !isNil(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && viewType === 'list' &&
        <BookingsTable bookings={ bookings } />
      }

      { isCached && viewType === 'calendar' &&
        <BookingsCalendar bookings={ allBookings } dateRange={ dateRange } />
      }
    </Layout>
  )
}

export default BookingsScreen
