import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { both, reverse, sortBy, map, head, toPairs, path, propEq, filter, prop, groupBy } from 'ramda'

import { sliceSelector, allSelector } from '../state/alerts-selectors'
import { fetch, markAsProcessed } from '../state/alerts-reducer'

import { typeLabelMap } from '../state/alerts-helpers'
import {DATETIME_FORMAT, SERVER_DATE_FORMAT} from '../../helpers'

import DateRangePicker from '../../components/date-range-picker'

const splitOptions = [
  { label: 'Type', value: 'type' },
  { label: 'Employé', value: 'employee' },
  { label: 'Status', value: 'status' },
]
const statusOptions = [
  { label: 'Non résolu', value: 'active' },
  { label: 'Archivé', value: 'archived' },
]

const Alert = (props) => {
  const { alert, onProcess } = props
  const { type, content, createdAt } = alert

  const typeLabel = typeLabelMap[type]
  return (
    <div className="alerts-overview-item">
      <div
        className="alerts-overview-item__process"
        onClick={ () => onProcess(alert._id) }
      >Archiver</div>

      <span className="alerts-overview-item__type">
        { typeLabel }
      </span>
      <span className="alerts-overview-item__date">
        { moment(createdAt).format(DATETIME_FORMAT) }
      </span>

      { type === 'problem' &&
        <>
          <span className="alerts-overview-item__meta">{ content.location }</span>
          <span className="alerts-overview-item__description">{ content.description }</span>
        </>
      }
      { type === 'loan' &&
        <>
          <span className="alerts-overview-item__meta">Prêté à { alert.content.to.firstName } { alert.content.to.lastName } ({ alert.content.to.type }, { alert.content.to.type === 'Client' ? `salle ${alert.content.to.venue}` : `chambre ${alert.content.to.room}` })</span>
          <span className="alerts-overview-item__description">
            { content.item === 'Autre' ? content.itemDescription : content.item }
          </span>
        </>
      }
      { type === 'booking' &&
        <>
          <span className="alerts-overview-item__meta">
            Salle { content.venue }
          </span>
          <span className="alerts-overview-item__meta">
            { moment(content.start).format(DATETIME_FORMAT) } au { moment(content.end).format(DATETIME_FORMAT) }
          </span>
          <span className="alerts-overview-item__description">{ content.customer.company } ({ content.customer.name })</span>
          <span className="alerts-overview-item__description">{ content.customer.email }, { content.customer.phone }</span>
        </>
      }
      { type === 'other' &&
        <>
          <span className="alerts-overview-item__meta">{ content.location }</span>
          <span className="alerts-overview-item__description">{ content.description }</span>
        </>
      }
    </div>
  )
}

const Group = (props) => {
  const { value, list, onProcess } = props
  return (
    <div className="alerts-overview__group">
      <span>{ value }</span>

      <div className="alerts-overview__group-items">
        { map((item) =>
          <Alert alert={ item } key={ item._id } onProcess={ onProcess } />
        , list) }
      </div>
    </div>
  )
}

const Control = (props) => {
  const {
    onChange,
    value: selectedValue,
    suggestions,
    label,
  } = props

  return (
    <div>
      <span>{ label }</span>

      <ul>
        { map(({ label, value }) => (
          <li
            key={ `control-option-${value}` }
            className={ value === selectedValue ? 'active' : '' }
            onClick={ () => onChange(value) }
          >{ label }</li>
        ), suggestions) }
      </ul>
    </div>
  )
}

const applyFilter = (filters, list) => {
  const { status, dateRange } = filters

  const statusPredicate = status === 'active'
    ? propEq('isProcessed', false)
    : propEq('isProcessed', true)

  const dateRangePredicate = (item) => {
    const { createdAt } = item
    if (!dateRange) return true
    return moment(createdAt).isBetween(dateRange.startDate, dateRange.endDate)
  }

  const predicate = both(statusPredicate, dateRangePredicate)

  return filter(predicate, list)
}

const applySplit = (split, list) => {

  const fn = split === 'employee'
    ? prop('createdBy')
    : split === 'status'
      ? prop('isProcessed')
      : prop('type')

  const splitValue = split === 'employee'
    ? path([ '_createdBy', 'displayName' ])
    : split === 'status'
      ? (i) => i.isProcessed ? 'Archivé' : 'Non résolu'
      : (i) => typeLabelMap[i.type]

  const grouped = groupBy(fn, list)
  return map(([ _key, val ]) => {
    const el = head(val)
    if (!el) return { value: '', list: val }
    return { value: splitValue(el), list: val }
  }, toPairs(grouped))
}

const AlertsOverview = (props) => {
  const dispatch = useDispatch()
  const { alerts } = props
  const [ split, setSplit ] = useState('type')
  const [ status, setStatus ] = useState('active')
  const [ dateRange, setDateRange ] = useState({
    startDate: moment().startOf('year').format(SERVER_DATE_FORMAT),
    endDate: moment().endOf('year').format(SERVER_DATE_FORMAT),
  })

  const handleClickControl = (setter) => (value) =>
    setter(value)

  const filteredAlerts = applyFilter({ status, dateRange }, reverse(sortBy(prop('createdAt'), alerts)))
  const groupedAlerts = applySplit(split, filteredAlerts)

  const handleProcess = (id) => dispatch(markAsProcessed(id))

  return (
    <div className="content">
      <div className="alerts-overview">
        <div className="alerts-overview__bar">
          <h3>Signalements</h3>

          <div className="alerts-overview__controls">
            <Control
              suggestions={ splitOptions }
              label="Voir par"
              value={ split }
              onChange={ handleClickControl(setSplit) }
            />
            <Control
              suggestions={ statusOptions }
              label="Status"
              value={ status }
              onChange={ handleClickControl(setStatus) }
            />
            <DateRangePicker
              dateRange={ dateRange }
              onChange={ setDateRange }
            />
          </div>
        </div>

        <div className="alerts-overview__groups">
          { map((group) =>
            <Group { ...group } key={ group.value } onProcess={ handleProcess } />
          , groupedAlerts) }
        </div>
      </div>
    </div>
  )
}

export default (props) => {
  const dispatch = useDispatch()
  const { all: { isCached } } = useSelector(sliceSelector)
  const alerts = useSelector(allSelector)

  useEffect(() => {
    if (!isCached) dispatch(fetch()) 
  }, [ dispatch, isCached ])

  if (!isCached) return <div data-uk-spinner></div>

  return <AlertsOverview { ...props } alerts={ alerts } />
}
