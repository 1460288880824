import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty, defaultTo, join, all, identity, reject, isNil } from 'ramda'

import Layout from '../../components/layout'
import SchedulesTable from '../../schedules/components/schedules-table'

import { sliceSelector, itemSelector } from '../../users/state/users-selectors'
import { fetch, del } from '../../users/state/users-reducer'
import { sliceSelector as schedulesSliceSelector, forEmployeeSelector as schedulesForEmployeeSelector } from '../../schedules/state/schedules-selectors'
import { fetch as fetchSchedules } from '../../schedules/state/schedules-reducer'

import EmployeeStatistics from '../components/statistics'

const EmployeeDetailsScreen = (props) => {
  const { match: { params: { employeeId } } } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const { all: { isFetching: isFetchingUsers, isCached: isUsersCached, error: usersError } } = useSelector(sliceSelector)
  const employee = useSelector(itemSelector(employeeId))
  const { all: { isFetching: isFetchingSchedules, isCached: isSchedulesCached, error: schedulesError } } = useSelector(schedulesSliceSelector)
  const schedules = useSelector(schedulesForEmployeeSelector(employeeId))

  useEffect(() => {
    if (!isUsersCached && !isFetchingUsers) dispatch(fetch())
    if (!isSchedulesCached && !isFetchingSchedules) dispatch(fetchSchedules())
  }, [ dispatch, isUsersCached, isFetchingUsers, isSchedulesCached, isFetchingSchedules ])

  const error = join(', ', reject(isNil, [ schedulesError, usersError ]))
  const isFetching = all(identity, [ isFetchingUsers, isFetchingSchedules ])
  const isCached = all(identity, [ isSchedulesCached, isUsersCached ])

  const hasError = !isEmpty(error) || !employee

  const handleClickEdit = () =>
    history.push(`/employees/${employee._id}/edit`)
  const handleClickDelete = () =>
    dispatch(del(employee._id, identity, () => history.push('/employees')))

  return (
    <Layout>
      { isFetching && <div data-uk-spinner></div> }
      { !isFetching && hasError &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ defaultTo(`User with id ${employeeId} does not exist.`, error) }</p>
        </div>
      }

      { isCached && !hasError &&
        <>
        <div className="uk-flex uk-flex-between">
          <h3>Fiche employé</h3>

          <div>
            <button className="uk-button uk-button-secondary" onClick={ handleClickEdit }>Modifier</button>
            <button className="uk-button uk-button-danger" onClick={ handleClickDelete }>Supprimer</button>
          </div>
        </div>

          <div className="uk-margin-medium-top">
            <h4 className="uk-heading-bullet"><span>Informations</span></h4>

            <table className="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td className="uk-text-bold">Prénom</td>
                  <td className="uk-text-left">{ employee.firstName }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Nom</td>
                  <td className="uk-text-left">{ employee.lastName }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Email</td>
                  <td className="uk-text-left">{ employee.email }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Numéro de téléphone</td>
                  <td className="uk-text-left">{ employee.phone }</td>
                </tr>
                { employee.address &&
                  <>
                    <tr>
                      <td className="uk-text-bold">Adresse</td>
                      <td className="uk-text-left">{ employee.address.line }, { employee.address.zip } { employee.address.city } ({ employee.address.country })</td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
          </div>

          <EmployeeStatistics
            employee={ employee }
            schedules={ schedules }
          />

          <div className="uk-margin-small-top">
            <h4 className="uk-heading-bullet"><span>Créneaux</span></h4>

            <SchedulesTable schedules={ schedules } />
          </div>
        </>
      }
    </Layout>  
  )
}

export default EmployeeDetailsScreen
