import React, { useState, useEffect } from 'react'
import { isNil, path, compose, map, update, defaultTo, identity, sortBy, prop } from 'ramda'
import moment from 'moment'

import { DATETIME_FORMAT, SERVER_DATETIME_FORMAT, pickDeepDefined, DATE_FORMAT, SERVER_DATE_FORMAT } from '../../helpers'

import ClientForm from '../../clients/components/client-form'
import DateInput from '../../components/date-input'
import { useDispatch } from 'react-redux'
import { create as createClient } from '../../clients/state/clients-reducer'
import TimeInput from '../../components/time-input'
import MultiDateInput from '../../components/multi-date-input'

const getTargetValue = path([ 'target', 'value' ])
const pathOr = (f, p, o) => defaultTo(f, path(p, o))

const BookingsForm = (props) => {
  const { clients, venues, initialFormData, setFormData, onDelete, i } = props
  const isEditing = !isNil(initialFormData)
  const dispatch = useDispatch()

  const [ client, setClient ] = useState(pathOr('', [ '_client', '_id' ], initialFormData))
  const [ venue, setVenue ] = useState(pathOr('', [ '_venue', '_id' ], initialFormData))

  const [ dateSetting, setDateSetting ] = useState('range') 
  const [ dates, setDates ] = useState([])

  const defaultStartDate = moment()
  const initialStart = pathOr(defaultStartDate, [ 'start' ], initialFormData)
  const initialStartDateTime = moment(initialStart)
  const initialStartDate = initialStartDateTime.format(SERVER_DATE_FORMAT)
  const initialStartTime = initialStartDateTime.format('HH:mm')
  const [ startDate, setStartDate ] = useState(initialStartDate)
  const [ startTime, setStartTime ] = useState(initialStartTime)
  const start = `${startDate}T${startTime}`

  const defaultEndDate = moment().add(2, 'hours')
  const initialEnd = pathOr(defaultEndDate, [ 'end' ], initialFormData)
  const initialEndDateTime = moment(initialEnd)
  const initialEndDate = initialEndDateTime.format(SERVER_DATE_FORMAT)
  const initialEndTime = initialEndDateTime.format('HH:mm')
  const [ endDate, setEndDate ] = useState(initialEndDate)
  const [ endTime, setEndTime ] = useState(initialEndTime)
  const end = `${endDate}T${endTime}`
  
  const [ isConfirmed, setIsConfirmed ] = useState(pathOr(false, [ 'isConfirmed' ], initialFormData))

  const [ amount, setAmount ] = useState(pathOr(0, [ 'invoice', 'amount' ], initialFormData))

  const [ tables, setTables ] = useState(pathOr(undefined, [ 'content', 'tables' ], initialFormData))
  const [ chairs, setChairs ] = useState(pathOr(undefined, [ 'content', 'chairs' ], initialFormData))
  const [ disposition, setDisposition ] = useState(pathOr('', [ 'content', 'disposition' ], initialFormData))
  const [ material, setMaterial ] = useState(pathOr([], [ 'content', 'material' ], initialFormData))
  const [ comments, setComments ] = useState(pathOr('', [ 'content', 'comments' ], initialFormData))
  
  const [ notes, setNotes ] = useState(pathOr('', [ 'notes' ], initialFormData))

  const [ clientFormData, setClientFormData ] = useState({})
  
  useEffect(() => {
    const datePayload = dateSetting === 'multiple'
      ? { dates, startTime, endTime }
      : { start: moment(start, 'YYYY-MM-DD[T]HH:mm').format(SERVER_DATETIME_FORMAT), end: moment(end, 'YYYY-MM-DD[T]HH:mm').format(SERVER_DATETIME_FORMAT) }

    setFormData(update(i - 1, {
      venueId: venue,
      clientId: client,
      ...datePayload,
      isConfirmed,
      content: {
        tables,
        chairs,
        disposition,
        material,
        comments,
      },
      notes,
      invoice: { isSent: false, amount },
      contract: { isSent: false }
    }))
  }, [ client, notes, venue, start, end, isConfirmed, amount, tables, chairs, disposition, material, comments, dateSetting, dates, startTime, endTime, i, setFormData ])

  const handleDelete = () => onDelete(i - 1)
  const handleAddClient = () => {
    dispatch(createClient(pickDeepDefined(clientFormData), (c) => setClient(c._id), identity))
  }

  return (
    <>
    <div id="clientModal" data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body">
          <h2 className="uk-modal-title">Nouveau client</h2>
          <button className="uk-modal-close-default" type="button" data-uk-close></button>

          <ClientForm setFormData={ setClientFormData } />
          <button className="uk-button uk-button-primary uk-align-right" data-uk-toggle="target: #clientModal" onClick={ handleAddClient }>Ajouter</button>
      </div>
    </div>
    <form className="uk-form-horizontal">
      { !initialFormData && 
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <h4>Réservation #{ i }</h4>
          <span data-uk-icon="icon: close" className="uk-text-danger cp" onClick={ handleDelete }>Supprimer </span>
        </div>
      }

      <div className="uk-margin">
        <label className="uk-form-label">Client *</label>
          <div className="uk-form-controls uk-flex">
            <select className="uk-select" value={ client } onChange={ compose(setClient, getTargetValue) }>
              <option disabled value="" />
              { map((client) => <option value={ client._id } key={ client._id }>{ client.company }</option>, sortBy(prop('company'), clients)) }
            </select>
            
            <button className="uk-button uk-button-secondary" data-uk-toggle="target: #clientModal" type="button">Ajouter</button>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Informations supplémentaires</label>
        <div className="uk-form-controls">
          <textarea className="uk-textarea" rows="2" value={ notes } onChange={ compose(setNotes, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Salle *</label>
        <div className="uk-form-controls">
          <select className="uk-select" value={ venue } onChange={ compose(setVenue, getTargetValue) }>
            <option disabled value="" />
            { map((venue) => <option value={ venue._id } key={ venue._id }>{ venue.name }</option>, venues) }
          </select>
        </div>
      </div>

      { !isEditing &&
        <div className="uk-margin">
          <label className="uk-form-label">Créer la réservation</label>
          <div className="uk-form-controls">
            <input className="uk-radio" type="radio" name="dateSetting" checked={ dateSetting === 'range' } onChange={ () => setDateSetting('range') } /> En créneau
            <input className="uk-radio uk-margin-left" type="radio" name="dateSetting" checked={ dateSetting === 'multiple' } onChange={ () => setDateSetting('multiple') } /> Sur plusieures dates
          </div>
        </div>
      }

      <div className="uk-margin">
        <label className="uk-form-label">Dates *</label>
        { dateSetting === 'range' &&
          <div className="uk-form-controls uk-grid-small" data-uk-grid>
            <div className="uk-width-1-2 uk-padding-remove-left uk-padding-right">
              <DateInput
                value={ startDate }
                onChange={ setStartDate }
              />
              <TimeInput
                value={ startTime }
                onChange={ setStartTime }
              />
            </div>
            <div className="uk-width-1-2 uk-padding-left">
              <DateInput
                value={ endDate }
                onChange={ setEndDate }
              />
              <TimeInput
                value={ endTime }
                onChange={ setEndTime }
              />
            </div>
          </div>
        }
        { dateSetting === 'multiple' &&
          <div className="uk-form-controls uk-grid-small" data-uk-grid>
            <div className="uk-width-1 uk-padding-remove-left">
              <MultiDateInput
                value={ dates }
                onChange={ setDates }
              />
            </div>
            <div className="uk-width-1-2 uk-padding-remove-left uk-padding-right">
              <TimeInput
                value={ startTime }
                onChange={ setStartTime }
              />
            </div>
            <div className="uk-width-1-2 uk-padding-left">
              <TimeInput
                value={ endTime }
                onChange={ setEndTime }
              />
            </div>
          </div>
        }
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Status *</label>
        <div className="uk-form-controls">
          <input className="uk-radio" type="radio" name="status" checked={ !isConfirmed } onChange={ () => setIsConfirmed(false) } /> Option
          <input className="uk-radio uk-margin-left" type="radio" name="status" checked={ isConfirmed } onChange={ () => setIsConfirmed(true) } /> Confirmée
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Tables & chaises</label>
        <div className="uk-form-controls uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2 uk-padding-remove-left uk-padding-right">
            <input type="number" className="uk-input" placeholder="Tables" value={ tables } onChange={ compose(setTables, getTargetValue) } />
          </div>
          <div className="uk-width-1-2 uk-padding-left">
            <input type="number" className="uk-input" placeholder="Chaises" value={ chairs } onChange={ compose(setChairs, getTargetValue) } />
          </div>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Disposition</label>
        <div className="uk-form-controls">
          <select className="uk-select" value={ disposition } onChange={ compose(setDisposition, getTargetValue) }>
            <option disabled value="" />
            <option value="Auditoire">Auditoire</option>
            <option value="U fermé">U fermé</option>
            <option value="U ouvert">U ouvert</option>
            <option value="Autre">Autre</option>
          </select>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Détails de mise en place</label>
        <div className="uk-form-controls">
          <textarea className="uk-textarea" rows="2" value={ comments } onChange={ compose(setComments, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Prix facturé (CHF)</label>
        <div className="uk-form-controls">
          <input type="number" className="uk-input" value={ amount } onChange={ compose(setAmount, getTargetValue) } />
        </div>
      </div>
    </form>
    </>
  )
}

export default BookingsForm
