import React from 'react'
import { merge } from 'ramda'
import { getPositionStyles } from '../../helpers/chart-helpers'

const NumberChart = (props) => {
  const {
    title,
    serie,
    coordinates,
  } = props
  const {
    total: value,
  } = serie

  const positionStyles = getPositionStyles(props)

  const fontSize = coordinates.w > 5
    ? '3rem'
    : `${coordinates.w * .6}rem`

  const valueStyles = {
    fontSize,
  } 

  return (
    <div className="chart chart--number" style={ merge(positionStyles, valueStyles) }>
      <span className="chart__label">{ title }</span>

      <div className="chart__content">
        <span className="chart--number__value">{ value }</span>
      </div>
    </div>
  )
}

export default NumberChart
