import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { join, reject, isNil, isEmpty } from 'ramda'

import { sliceSelector, allSelector, itemSelector } from '../state/procedures-selectors'
import { fetch, update } from '../state/procedures-reducer'
import { getCategoryTree } from '../state/procedures-helpers'

import Layout from '../../components/layout'
import ProcedureForm from '../components/procedure-form'

const EditProcedureScreen = (props) => {
  const { match: { params: { procedureId } } } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { all: { isFetching, isCached, error: proceduresError } } = useSelector(sliceSelector)
  const procedures = useSelector(allSelector)
  const procedure = useSelector(itemSelector(procedureId))

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetch())
  }, [ dispatch, isCached, isFetching ])

  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ proceduresError, globalError ]))

  const [ formData, setFormData ] = useState({})

  const categories = getCategoryTree(procedures)

  const handleSubmit = () => {
    dispatch(update(procedureId, formData, () => history.push('/procedures'), setGlobalError))
  }
  return (
    <Layout>
      <h3>Modifier la procédure</h3>

      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && !isFetching &&
        <ProcedureForm { ...{ initialFormData: procedure, categories, setFormData } } />
      }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" disabled={ isFetching } onClick={ handleSubmit }>Modifier</button>
      </div>
    </Layout>
  )
}

export default EditProcedureScreen
