import { createSlice } from '@reduxjs/toolkit'
import { pluck, assoc, append, dissoc, reject, equals, merge, uniq, concat, assocPath, compose } from 'ramda'
import { mapIdToObj } from '../../helpers.js'

import request from '../../request'

const initialState = {
  byId: {},
  all: {
    ids: [],
    isCached: false,
    isFetching: false,
    error: null,
  }
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetching: (state, action) => {
      return assocPath([ 'all', 'isFetching' ], true, state)
    },
    notFetched: (state, action) => {
      return compose(
        assocPath([ 'all', 'error' ], action.payload),
        assocPath([ 'all', 'isFetching' ], false)
      )(state)
    },
    fetched: (state, action) => {
      const byId = mapIdToObj(action.payload)
      const ids = pluck('_id', action.payload)
      
      return {
        byId: merge(state.byId, byId),
        all: { ids: uniq(concat(state.all.ids, ids)), isCached: true, isFetching: false }
      }
    },
    fetchedOne: (state, action) => {
      const byId = assoc(action.payload._id, action.payload, state.byId)
      const ids = uniq(append(action.payload._id, state.all.ids))

      return { byId, all: assoc('ids', ids, state.all) }
    },
    hadError: (state, action) => assocPath([ 'all', 'error' ], action.payload, state),
    deletedOne: (state, action) => {
      const byId = dissoc(action.payload, state.byId)
      const ids = reject(equals(action.payload), state.all.ids)

      return { byId, all: assoc('ids', ids, state.all) }
    },
  },
})

const { fetching, fetched, notFetched, fetchedOne, deletedOne } = slice.actions

export const fetch = () => async (dispatch) => {
  try {
    dispatch(fetching())
    const { data: { users } } = await request.get('/users')
    dispatch(fetched(users))
  } catch (err) {
    dispatch(notFetched(err.toString()))
  }
}

export const create = (partialUser, onSuccess, onError) => async (dispatch) => {
  try {
    const { data: { user } } = await request.post('/users', partialUser)
    dispatch(fetchedOne(user))
    onSuccess(user)
  } catch (err) {
    onError(err.toString())
  }
}

export const update = (userId, partialUser, onSuccess, onError) => async (dispatch) => {
  try {
    const { data: { user } } = await request.put(`/users/${userId}`, partialUser)

    dispatch(fetchedOne(user))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const del = (userId, onError, onSuccess) => async (dispatch) => {
  try {
    await request.delete(`/users/${userId}`)

    dispatch(deletedOne(userId))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export default slice.reducer