import { createSlice } from '@reduxjs/toolkit'
import { pluck, merge, uniq, concat, assocPath, compose, assoc, append } from 'ramda'
import { mapIdToObj, denormalizeEntities, denormalizeEntity } from '../../helpers.js'

import { fetch as fetchUsers } from '../../users/state/users-reducer'
import request from '../../request'

const initialState = {
  byId: {},
  all: {
    ids: [],
    isCached: false,
    isFetching: false,
    error: null,
  }
}

const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    fetching: (state, action) => {
      return assocPath([ 'all', 'isFetching' ], true, state)
    },
    notFetched: (state, action) => {
      return compose(
        assocPath([ 'all', 'error' ], action.payload),
        assocPath([ 'all', 'isFetching' ], false)
      )(state)
    },
    fetched: (state, action) => {
      const byId = mapIdToObj(action.payload)
      const ids = pluck('_id', action.payload)
      
      return {
        byId: merge(state.byId, byId),
        all: { ids: uniq(concat(state.all.ids, ids)), isCached: true, isFetching: false }
      }
    },
    fetchedOne: (state, action) => {
      const byId = assoc(action.payload._id, action.payload, state.byId)
      const ids = uniq(append(action.payload._id, state.all.ids))

      return { byId, all: assoc('ids', ids, state.all) }
    },
  },
})

const { fetching, fetched, fetchedOne, notFetched } = slice.actions

export const fetch = () => async (dispatch, getState) => {
  try {
    dispatch(fetching())

    const { data: { alerts } } = await request.get('/alerts')

    const initialState = getState()
    const { users: { all: { isCached: isUsersCached } } } = initialState
    if (!isUsersCached) {
      await fetchUsers()(dispatch, getState)
    }

    const state = getState()
    const denormalized = denormalizeEntities(state, alerts)
    dispatch(fetched(denormalized))
  } catch (err) {
    dispatch(notFetched(err.toString()))
  }
}

export const markAsProcessed = (alertId, onSuccess = () => {}, onError = () => {}) => async (dispatch, getState) => {
  try {
    const { data: { alert } } = await request.put(`/alerts/${alertId}/isProcessed`)

    const initialState = getState()
    const { users: { all: { isCached: isUsersCached } } } = initialState
    if (!isUsersCached) {
      await fetchUsers()(dispatch, getState)
    }

    const state = getState()
    const denormalized = denormalizeEntity(state, alert)

    dispatch(fetchedOne(denormalized))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export default slice.reducer