import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNil, reject, join, isEmpty } from 'ramda'

import Layout from '../../components/layout'
import ProcedureForm from '../components/procedure-form'

import { sliceSelector, allSelector } from '../state/procedures-selectors'
import { create, fetch } from '../state/procedures-reducer'
import { getCategoryTree } from '../state/procedures-helpers'

const CreateProcedureScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { all: { isFetching, isCached, error: proceduresError } } = useSelector(sliceSelector)
  const procedures = useSelector(allSelector)

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetch()) 
  }, [ dispatch, isCached, isFetching ])

  const [ formData, setFormData ] = useState({})
  const [ globalError, setGlobalError ] = useState()

  const error = join(', ', reject(isNil, [ globalError, proceduresError ]))

  const categories = getCategoryTree(procedures)

  const handleSubmit = () => {
    dispatch(create(formData, () => history.push('/procedures'), setGlobalError))
  }

  return (
    <Layout>
      <h3>Ajouter une procédure</h3>

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached &&
        <ProcedureForm categories={ categories } setFormData={ setFormData } />
      }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" onClick={ handleSubmit }>Ajouter</button>
      </div>
    </Layout>
  )
}

export default CreateProcedureScreen