import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { identity, isNil, either, isEmpty } from 'ramda'

import { formatCurrency, DATETIME_FORMAT } from '../../helpers'
import { sliceSelector, itemSelector } from '../state/bookings-selectors'
import { fetch, del } from '../state/bookings-reducer'

import Layout from '../../components/layout'

const isEmptyOrNil = either(isEmpty, isNil)

const BookingDetailsScreen = (props) => {
  const { match: { params: { bookingId } } } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const booking = useSelector(itemSelector(bookingId))

  useEffect(() => {
    if (!isCached) dispatch(fetch()) 
  }, [ dispatch, isCached ])

  const hasError = !isNil(error) || !booking
  const layoutProps = { isLoading: isFetching, hasError, error }

  if (isFetching || hasError) return <Layout { ...layoutProps } />

  const handleEdit = () => history.push(`/bookings/${booking._id}/edit`)
  const handleDelete = () => {
    dispatch(del(bookingId, () => history.push('/bookings', { viewType: 'calendar' }), identity))
  }

  return (
    <Layout { ...layoutProps }>
      <h3>Réservation du { moment(booking.start).format('D MMMM YYYY') } <span className="uk-text-light">({ moment(booking.start).format('HH:mm') } à { moment(booking.end).format('HH:mm') })</span></h3>
      
      <div data-uk-grid>
        <div className="uk-width-2-3@m uk-margin-small-top">
          <h4 className="uk-heading-line"><span>Informations</span></h4>
          <table className="uk-table uk-table-divider">
            <tbody>
              <tr>
                <td className="uk-text-bold">Type de réservation</td>
                <td className="uk-text-left uk-table-expand">{ booking.isConfirmed ? 'Confirmée' : 'Option' }</td>
              </tr>
              <tr>
                <td className="uk-text-bold">Salle</td>
                <td className="uk-text-left">{ booking._venue.name }</td>
              </tr>
              <tr>
                <td className="uk-text-bold">Durée de la réservation</td>
                <td className="uk-text-left">{ moment(booking.end).diff(booking.start, 'hours') } heures</td>
              </tr>
              { !isEmptyOrNil(booking.notes) &&
                <tr>
                  <td className="uk-text-bold">Informations supplémentaires</td>
                  <td className="uk-text-left">{ booking.notes }</td>
                </tr>
              }
            </tbody>
          </table>

          <div className="ukk-margin-medium-top">
            <h4 className="uk-heading-line"><span>Mise en place</span></h4>

            <table className="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td className="uk-text-bold">Tables</td>
                  <td className="uk-text-left uk-table-expand">{ booking.content.tables }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Chaises</td>
                  <td className="uk-text-left uk-table-expand">{ booking.content.chairs }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Disposition</td>
                  <td className="uk-text-left uk-table-expand">{ booking.content.disposition }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Détails</td>
                  <td className="uk-text-left uk-table-expand">{ booking.content.comments }</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="uk-margin-medium-top">
            <h4 className="uk-heading-line"><span>Contrat</span></h4>
            { booking.contract.isSent &&
              <div className="uk-flex uk-flex-between uk-flex-middle">
                <div>Contrat envoyé le { moment(booking.contract.sentAt).format(DATETIME_FORMAT) } par { booking.contract.sentBy.displayName }</div>
                <button className="uk-button uk-button-secondary">Télécharger</button>
              </div>
            }
            
            { !booking.contract.isSent &&
              <div className="js-upload uk-placeholder uk-text-center">
                <span data-uk-icon="icon: cloud-upload"></span>
                <span className="uk-text-middle"> Attachez un document en le déposant ici</span>
              </div>
            }
          </div>

          <div className="uk-margin-medium-top">
            <h4 className="uk-heading-line"><span>Facture</span></h4>

            <table className="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td className="uk-text-bold">Montant</td>
                  <td className="uk-text-left uk-table-expand">{ formatCurrency(booking.invoice.amount) }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Facture envoyée</td>
                  <td className="uk-text-left">{ booking.invoice.isSent ? <span data-uk-icon="icon: check" /> : <span data-uk-icon="icon: close" /> }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Facture payée</td>
                  <td className="uk-text-left">{ booking.invoice.isPaid ? <span data-uk-icon="icon: check" /> : <span data-uk-icon="icon: close" /> }</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-width-1-3@m">
          { !isNil(booking._client._id) &&
            <div className="uk-card uk-card-body uk-card-default uk-text-center">
              <div><span className="uk-text-bold">{ booking._client.company }</span> ({ booking._client.name })</div>
              <div className="uk-margin-top"><span data-uk-icon="mail" /> { booking._client.email }</div>
              <div><span data-uk-icon="receiver" /> { booking._client.phone }</div>
              <div className="uk-margin-small-top">
                <div>{ booking._client.address.line }</div>
                <div>{ booking._client.address.zip }, { booking._client.address.city } ({ booking._client.address.country })</div>
              </div>
            </div>
          }
          <button className="uk-button uk-button-secondary uk-width-1-2" onClick={ handleEdit }>Modifier</button>
          <button className="uk-button uk-button-danger uk-width-1-2" onClick={ handleDelete }>Supprimer</button>
        </div>
      </div>
    </Layout>  
  )
}

export default BookingDetailsScreen
