import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  filter,
  pathEq,
} from 'ramda'

import { formatCurrency, summarizeProp, DATE_FORMAT } from '../../helpers'
import { updateInvoiceSentStatus, updateInvoicePaidStatus } from '../state/bookings-reducer'
import DataTable from '../../components/data-table'

const Row = ({ handleClickBooking, handleInvoiceSentStatusClick, handleInvoicePaidStatusClick }) => ({ item: booking }) => {
  const {
    start,
    end,
    _venue,
    _client,
    isConfirmed,
    invoice,
    contract
  } = booking

  return (
    <tr onClick={ handleClickBooking(booking._id) }>
      <td></td>
      <td>
        <div className="align-center">{ moment(start).format(DATE_FORMAT) }</div>
        <div className="align-center">{ moment(start).format('HH:mm') } à { moment(end).format('HH:mm') }</div>
      </td>
      <td>{ moment(start).format('MMMM') }</td>
      <td>{ _venue.name }</td>
      <td>{ _client.company }</td>
      <td><span data-uk-icon={ `icon: ${invoice.isPaid ? 'check' : 'close'}` } /></td>
      <td><span data-uk-icon={ `icon: ${invoice.isSent ? 'check' : 'close'}` } /></td>
      <td>{ isConfirmed ? <span data-uk-icon="icon: check" /> : <span data-uk-icon="icon: close" /> }</td>
      <td>{ invoice.amount && formatCurrency(invoice.amount) }</td>
      <td>{ contract.isSent ? <span data-uk-icon="icon: check" /> : <span data-uk-icon="icon: close" /> }</td>
      <td onClick={ (e) => e.stopPropagation() }>
        <button
          className={ `uk-button uk-margin-small-left ${invoice.isSent ? 'uk-button-secondary' : 'uk-button-primary'}` }
          onClick={ handleInvoiceSentStatusClick(booking._id, !invoice.isSent) }
        >
          { invoice.isSent ? 'Marquer comme non facturée' : 'Marquer comme facturée' }
        </button>
        <button
          className={ `uk-button uk-margin-small-left ${invoice.isPaid ? 'uk-button-secondary' : 'uk-button-primary'}` }
          onClick={ handleInvoicePaidStatusClick(booking._id, !invoice.isPaid) }
        >
          { invoice.isPaid ? 'Marquer comme non payée' : 'Marquer comme payée' }
        </button>
      </td>
    </tr>
  )
}

const BookingsTable = ({ bookings }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const headings = [
    { label: 'Dates', type: 'date', prop: [ 'start' ] },
    { label: 'Mois', type: 'list', prop: [ 'start' ], filterable: true, getFilter: (v) => moment(v).format('MMMM') },
    { label: 'Salle', type: 'list', prop: [ '_venue', 'name' ], filterable: true },
    { label: 'Client', type: 'list', prop: [ '_client', 'company' ], filterable: true },
    { label: 'Payée', type: 'boolean', prop: [ 'invoice', 'isPaid' ], filterable: true, footer: (list) => {
      const filtered = filter(pathEq([ 'invoice', 'isPaid' ], true), list)
      const value = summarizeProp([ 'invoice', 'amount' ], filtered)
      return (
        <th key="footer-isPaid">
          <div className="align-center"><b>CA</b> { formatCurrency(value) }</div>
          <div className="align-center"><b>TTL</b> { filtered.length }</div>
        </th>
      )
    } },
    { label: 'Facturée', type: 'boolean', prop: [ 'invoice', 'isSent' ], filterable: true, footer: (list) => {
      const filtered = filter(pathEq([ 'invoice', 'isSent' ], true), list)
      const value = summarizeProp([ 'invoice', 'amount' ], filtered)
      return (
        <th key="footer-isSent">
          <div className="align-center"><b>CA</b> { formatCurrency(value) }</div>
          <div className="align-center"><b>TTL</b> { filtered.length }</div>
        </th>
      )
    } },
    { label: 'Confirmée', type: 'boolean', prop: [ 'isConfirmed' ], filterable: true, footer: (list) => {
      const filtered = filter(pathEq([ 'isConfirmed' ], true), list)
      const value = summarizeProp([ 'invoice', 'amount' ], filtered)
      return (
        <th key="footer-isConfirmed">
          <div className="align-center"><b>CA</b> { formatCurrency(value) }</div>
          <div className="align-center"><b>TTL</b> { filtered.length }</div>
        </th>
      )
    } },
    { label: 'Prix facturé', type: 'number', prop: [ 'invoice', 'amount' ], footer: (list) => {
      const value = summarizeProp([ 'invoice', 'amount' ], list)
      return (
        <th key="footer-amount">
          <div className="align-center"><b>CA</b> { formatCurrency(value) }</div>
          <div className="align-center"><b>TTL</b> { list.length }</div>
        </th>
      )
    } },
    { label: 'Contrat envoyé', type: 'boolean', prop: [ 'contract', 'isSent' ] },
    { label: '', prop: [], sortable: false }
  ]

  const handleClickBooking = (bookingId) => () => {
    history.push(`/bookings/${bookingId}`)
  }
  const handleInvoiceSentStatusClick = (bookingId, isSent) => () => {
    dispatch(updateInvoiceSentStatus(bookingId, isSent))
  }
  const handleInvoicePaidStatusClick = (bookingId, isPaid) => () => {
    dispatch(updateInvoicePaidStatus(bookingId, isPaid))
  }

  return (
    <DataTable
      list={ bookings }
      headings={ headings }
      rowRenderer={ Row({ handleClickBooking, handleInvoiceSentStatusClick, handleInvoicePaidStatusClick }) }
      initialSortIndex={ 0 }
    />
  )
}

export default BookingsTable
