import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { filter, defaultTo, isNil } from 'ramda'

import Layout from '../../components/layout'
import SchedulesTable from '../components/schedules-table'
import SchedulesCalendar from '../components/schedules-calendar'
import DateRangePicker from '../../components/date-range-picker'

import { SERVER_DATE_FORMAT } from '../../helpers'

import { sliceSelector, allSelector } from '../state/schedules-selectors'
import { fetch } from '../state/schedules-reducer'

const SchedulesScreen = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const allSchedules = useSelector(allSelector)

  const [ schedules, setSchedules ] = useState([])

  const [ viewType, setViewType ] = useState('calendar')

  const defaultDateRange = {
    startDate: moment().startOf('year').format(SERVER_DATE_FORMAT),
    endDate: moment().endOf('year').format(SERVER_DATE_FORMAT),
  }
  const [ dateRange, setDateRange ] = useState(defaultDateRange)

  useEffect(() => {
    const { location: { state } } = props
    setDateRange({
      startDate: defaultTo(defaultDateRange.startDate, state?.startDate),
      endDate: defaultTo(defaultDateRange.endDate, state?.endDate),
    })
  }, [ props.location.state ])

  const handleChangeDateRange = (newDateRange) => {
    const { match: { path } } = props
    history.push({
      pathname: path,
      state: newDateRange,
    })
  }

  useEffect(() => {
    if (!isCached) dispatch(fetch())
  }, [ dispatch, isCached ])

  useEffect(() => {
    const filteredSchedules = filter(({ plannedStart }) => {
      return moment(plannedStart).isBetween(
        moment(dateRange.startDate).startOf('day'),
        moment(dateRange.endDate).endOf('day')
      )
    }, allSchedules)
    setSchedules(filteredSchedules)
  }, [ allSchedules, dateRange ])

  const handleClickCreate = () => {
    history.push('/schedules/new')
  }
  return (
    <Layout>
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-bottom">
        <div>
          <h3 className="uk-margin-remove-bottom">
            Créneaux
          </h3>

          { viewType === 'list' &&
            <DateRangePicker dateRange={ dateRange } onChange={ handleChangeDateRange } />
          }
        </div>
        <div className="uk-flex uk-flex-middle">
          <ul className="uk-iconnav uk-margin-medium-right">
            <li><a data-uk-icon="icon: calendar" onClick={ () => setViewType('calendar') }></a></li> { /* eslint-disable-line */ }
            <li><a data-uk-icon="icon: list" onClick={ () => setViewType('list') }></a></li> { /* eslint-disable-line */ }
          </ul>
          <hr className="uk-divider-vertical uk-margin-remove" style={{ height: '50px' }} />
          <button className="uk-button uk-button-primary uk-margin-medium-left" onClick={ handleClickCreate }>Créer</button>
        </div>
      </div>

      { isFetching && <div data-uk-spinner></div> }
      { !isNil(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && viewType === 'list' &&
        <SchedulesTable schedules={ schedules } />
      }

      { isCached && viewType === 'calendar' &&
        <SchedulesCalendar schedules={ allSchedules } />
      }
    </Layout>
  )
}

export default SchedulesScreen
