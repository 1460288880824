import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { equals, map, assoc } from 'ramda'

import { userSelector } from '../auth/state/auth-selectors'

const isActivePredicate = (state) => (match, location) => {
  if (!match) return false
  return equals(state, location.state)
}

const availableRoutes = [
  { key: 'overview', label: 'Vue d\'ensemble', icon: 'thumbnails', path: '/dashboard' },
  { key: 'bookings', label: 'Réservations', icon: 'calendar', routes: [
    { key: 'calendar', label: 'Calendrier', icon: 'calendar', path: '/bookings', state: { viewType: 'calendar' } },
    { key: 'list', label: 'Liste', icon: 'list', path: '/bookings', state: { viewType: 'list' } },
    { key: 'clients', label: 'Clients', icon: 'users', path: '/clients' },
  ] },
  { key: 'staff', label: 'Staff', icon: 'users', routes: [
    { key: 'calendar', label: 'Créneaux', icon: 'clock', path: '/schedules' },
    { key: 'employees', label: 'Employé(e)s', icon: 'users', path: '/employees' },
  ] },
  { key: 'alerts', label: 'Signalements', icon: 'bolt', path: '/alerts' },
  { key: 'procedures', label: 'Procédures', icon: 'comments', path: '/procedures' },
  { key: 'logout', label: 'Déconnexion', icon: 'sign-out', path: '/logout', className: 'margin-top-auto navigation__menu__link--danger' }
]

const Link = (props) => {
  const { route } = props
  const { label, icon, className = '', path: pathname, state } = route

  return (
    <NavLink
      to={{ pathname, state }}
      isActive={ isActivePredicate(state) }
      className={ `navigation__menu__link ${className}` }
      activeClassName="navigation__menu__link--active"
    >
      <span className="navigation__menu__link__label">{ label }</span>
      <span className="navigation__menu__link__icon" data-uk-icon={ icon } />
    </NavLink>
  )
}

const LinkGroup = (props) => {
  const { route } = props
  const { label, icon, routes } = route

  const links = map((link) => {
    const { label } = link
    const amendedLink = assoc('label', label, link)
    return <Link route={ amendedLink } key={ amendedLink.key } />
  }, routes)

  return (
    <div
      className="navigation__menu__link-group"
    >
      <span className="navigation__menu__link-group__label">{ label }</span>
      <span className="navigation__menu__link-group__icon" data-uk-icon={icon} />

      <div className="navigation__menu__link-group__links">
        { links }
      </div>
    </div>
  )
}

const Menu = () => {
  const items = map((route) => {
    const { path } = route

    if (!path) return <LinkGroup route={ route } key={ route.key } />
    return <Link route={ route } key={ route.key } />
  }, availableRoutes)

  return (
    <ul className="navigation__menu">
      { items }
    </ul>
  )
}

const Navigation = () => {
  const user = useSelector(userSelector)

  const displayName = `${user.firstName} ${user.lastName.slice(0, 1)}.`

  return (
    <div className="navigation">

      <div className="navigation__info">
        <img className="navigation__info__image" src="/logo.png" alt="" />
        <div>
          <div className="navigation__info__company">Le Cazard</div>
          <div className="navigation__info__user">{ displayName }</div>
        </div>
      </div>

      <Menu />
    </div>
  )
}

export default Navigation
