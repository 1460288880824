import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  curry,
  sortBy,
  is,
  set,
  over,
  reject,
  append,
  lensProp,
  uniq,
  compose,
  propOr,
  map,
  descend,
  ascend,
  sort,
  path,
  addIndex,
  equals,
  assoc,
  pluck,
  isEmpty,
  filter,
  propEq,
  join,
  toPairs,
  find,
  pathEq,
  anyPass,
  flatten,
  always,
  allPass,
  identity,
  test,
} from 'ramda'

import { isEmptyOrNil } from '../helpers'
import useOutsideClick from './use-outside-click'

const mapIndexed = addIndex(map)
const getTargetValue = path([ 'target', 'value' ])
const isSortable = propOr(true, 'sortable')
const getSearchKeys = compose(
  pluck('prop'),
  filter(propEq('searchable', true))
)

const createSearchPredicate = curry((searchKeys, searchTerm, item) => {
  const match = find((searchKey) => {
    const value = path(searchKey, item)
    const regex = new RegExp(searchTerm, 'g')
    return test(regex, value)
  }, searchKeys)

  return match ? true : false
})

const createFilterPredicate = (headings, filters) => {
  const predicates = flatten(map(([ key, valueArr ]) => {
    if (isEmpty(valueArr)) return always(true)
    const heading = find(propEq('label', key), headings)

    return anyPass(map((value) => {
      return is(Function, heading.getFilter)
        ? (item) => heading.getFilter(path(heading.prop, item)) === value
        : pathEq(heading.prop, value)
    }, valueArr))
  }, toPairs(filters)))

  return allPass(predicates)
}

const DataTable = (props) => {
  const {
    list: initialList,
    headings,
    rowRenderer: Row,
    initialSortIndex = 0,
    initialSortOrder = 'asc',
    searchable = false,
  } = props

  const [ list, setList ] = useState(initialList)
  const [ sortSetting, setSortSetting ] = useState({ index: initialSortIndex, order: initialSortOrder })
  const [ searchTerm, setSearchTerm ] = useState('')
  const [ optionsModal, setOptionsModal ] = useState()
  const [ filters, setFilters ] = useState({})

  const searchKeys = useMemo(() => getSearchKeys(headings), [ headings ])

  const modalRef = useRef()

  useOutsideClick(modalRef, () => {
    if (isEmptyOrNil(optionsModal)) return
    setOptionsModal('')
  })

  useEffect(() => {
    const orderFn = sortSetting.order === 'desc' ? descend : ascend
    const comparatorFn = orderFn(path(headings[sortSetting.index].prop))
    const predicateFn = createFilterPredicate(headings, filters)

    const apply = compose(sort(comparatorFn), filter(predicateFn))
    
    setList(apply(initialList))
  }, [ filters, sortSetting, initialList, headings ])

  const handleSort = (index, order) => () => {
    if (!isSortable(headings[index])) return
    if (index === sortSetting.index) return setSortSetting({
      index,
      order
    })
    
    setSortSetting(assoc('index', index))
  }

  const handleFilterUpdate = (name, type, key) => (e) => {
    let updateFn = identity
    const filterLens = lensProp(name)

    if (type === 'list') {
      const { checked } = e.target

      updateFn = over(filterLens, (selectedFilters) => {
        return checked
          ? uniq(append(key, selectedFilters))
          : reject(equals(key), selectedFilters)
      })
    }

    if (type === 'boolean') {
      const value = Array.isArray(key) ? key : [ key ]
      updateFn = set(filterLens, value)
    }

    setFilters(updateFn)
  }

  const handleSearch = (e) => {
    e.preventDefault()

    if (isEmpty(searchTerm)) {
      const orderFn = sortSetting.order === 'desc' ? descend : ascend
      const comparatorFn = orderFn(path(headings[sortSetting.index].prop))
      const predicateFn = createFilterPredicate(headings, filters)

      const apply = compose(sort(comparatorFn), filter(predicateFn))

      return setList(apply(initialList))
    }
    const filteredList = filter(createSearchPredicate(searchKeys, searchTerm), list)
    setList(filteredList)
  }

  const handleOpenModal = (index) => () =>
    setOptionsModal(index)

  return (
    <div className="uk-margin-top uk-margin-bottom uk-overflow-auto data-table-wrapper">
      { searchable &&
        <div>
          <form className="uk-search uk-search-default uk-width-1-1 uk-margin-bottom" onSubmit={ handleSearch }>
              <span data-uk-search-icon></span>
              <input className="uk-search-input" type="search" placeholder="Rechercher..." value={ searchTerm } onChange={ compose(setSearchTerm, getTargetValue) } />
          </form>
        </div>
      }
      <table className="uk-table uk-table-divider uk-margin-remove">
        <thead>
            <tr>
              <th></th>
              { mapIndexed((heading, index) => (
                <th key={ heading.label }>
                  <div onClick={ handleOpenModal(index) } className="cp">
                    { heading.label }
                  </div>
                  { propOr(true, 'sortable', heading) && optionsModal === index &&
                    <div className="data-table__heading__options" ref={ modalRef }>
                      <div className="data-table__heading__options__sort">
                        <h5>Trier par</h5>
                        <span
                          className={ equals(sortSetting, { index, order: 'asc' }) ? 'active' : '' }
                          onClick={ handleSort(index, 'asc') }
                        >Ascendent</span>
                        <span
                          className={ equals(sortSetting, { index, order: 'desc' }) ? 'active' : '' }
                          onClick={ handleSort(index, 'desc') }
                        >Descendent</span>
                      </div>
                      { heading.filterable &&
                      <div className="data-table__heading__options__filters">
                        <h5>Filtrer</h5>

                        { heading.type === 'list' &&
                          <ul>
                            { map((item) => (
                              <li key={ item }>
                                <input
                                  type="checkbox"
                                  className="uk-checkbox"
                                  checked={ find(equals(item), filters[heading.label] || []) ? true : false }
                                  onChange={ handleFilterUpdate(heading.label, heading.type, item) }
                                /> { item }
                              </li>
                            ), sortBy(identity, uniq(map((item) => {
                              const val = path(heading.prop, item)
                              return is(Function, heading.getFilter)
                                ? heading.getFilter(val)
                                : val
                            }, initialList)))) }
                          </ul>
                        }

                        { heading.type === 'boolean' &&
                          <ul>
                            <li>
                              <input
                                type="checkbox"
                                className="uk-checkbox"
                                checked={ isEmpty(path([ heading.label ], filters)) }
                                onChange={ handleFilterUpdate(heading.label, heading.type, []) }
                              /> Tout
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                className="uk-checkbox"
                                checked={ path([ heading.label, 0 ], filters) === true }
                                onChange={ handleFilterUpdate(heading.label, heading.type, true) }
                              /> Oui
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                className="uk-checkbox"
                                checked={ path([ heading.label, 0 ], filters) === false }
                                onChange={ handleFilterUpdate(heading.label, heading.type, false) }
                              /> Non
                            </li>
                          </ul>
                        }
                      </div>
                      }
                    </div>
                  }
                </th>
              ), headings) }
            </tr>
        </thead>
        <tbody>
            { map((item) => (
              <Row item={ item } key={ item._id } />
            ), list) }
        </tbody>
        <tfoot>
          <tr>
            <th>
              <div className="align-center"><b>TTL</b></div>
              <div className="align-center">{ list.length }</div>
            </th>
            { map((item) =>
              item.footer
                ? item.footer(list)
                : <th key={ item.label }></th>
            , headings) }
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default DataTable
