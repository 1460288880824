import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map, defaultTo, isNil, forEach, identity } from 'ramda'

import Layout from '../../components/layout'

import { sliceSelector, itemSelector } from '../state/procedures-selectors'
import { fetch, del, uploadFiles, downloadFile, deleteFile } from '../state/procedures-reducer'

const ProcedureDetailsScreen = (props) => {
  const { match: { params: { procedureId } } } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const procedure = useSelector(itemSelector(procedureId))

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetch())
  }, [ dispatch, isCached, isFetching ])

  const hasError = !isNil(error) || !procedure

  const handleDownloadFile = (fileId) => () =>
    dispatch(downloadFile(procedureId, fileId))
  const handleFileUpload = (e) => {
    const formData = new FormData()
    forEach((file) => {
      formData.append('procedures', file)
    }, e.target.files)

    dispatch(uploadFiles(procedureId, formData))
  }
  const handleDeleteFile = (fileId) => () =>
    dispatch(deleteFile(procedureId, fileId))

  const handleDelete = () =>
    dispatch(del(procedureId, () => history.push('/procedures'), identity))

  const handleEdit = (e) => {
    e.preventDefault()
    history.push(`/procedures/${procedureId}/edit`)
  }

  return (
    <Layout>
      { isFetching && <div data-uk-spinner></div> }
      { !isFetching && hasError &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ defaultTo(`Procedure with id ${procedureId} does not exist.`, error) }</p>
        </div>
      }

      { isCached && !hasError &&
        <>
          <h3 className="uk-margin-bottom">Procédure</h3>

          <div className="procedure__header">
            <div className="procedure__info">
              <span className="procedure__info__name">{ procedure.name }</span>
              <span className="procedure__info__categories">{ procedure.categories[0] } &gt; { procedure.categories[1] }</span>
              <button className="uk-button uk-button-danger uk-margin-left" onClick={ handleDelete }>Supprimer</button>
              <button className="uk-button uk-button-secondary" onClick={ handleEdit }>Modifier</button>
            </div>

            <div className="procedure__attachments">
              <span className="procedure__attachments__label">Photos, vidéos, documents</span>

              <div className="js-upload uk-placeholder uk-text-center">
                <span data-uk-icon="icon: cloud-upload"></span>
                <span className="uk-text-middle"> Déposez des fichiers ici</span>
                  <input type="file" multiple className="uk-margin-left" onChange={ handleFileUpload } />
              </div>

              { map((fileKey) => (
                <div className="procedure__attachments__attachment" key={ fileKey }>
                  <span>{ fileKey }</span>

                  <div className="procedure__attachments__attachment__actions">
                    <button className="uk-button uk-button-secondary" onClick={ handleDownloadFile(fileKey) }>Télécharger</button>
                    <button className="uk-button uk-button-danger" onClick={ handleDeleteFile(fileKey) }>Supprimer</button>
                  </div>
                </div>
              ), procedure.files || []) }
            </div>
          </div>
          <p className="uk-margin-medium-top">{ procedure.content }</p>
        </>
      }
    </Layout>  
  )
}

export default ProcedureDetailsScreen
