import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const ProtectedRoute = (props) => {
  const { auth } = props
  const { isAuthenticated } = auth

  if (!isAuthenticated) return <Redirect to="/login" />

  return <Route { ...props } />
}

export default ProtectedRoute