import React from 'react'
import { useHistory } from 'react-router-dom'
import { map } from 'ramda'

import Calendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'

import { formatScheduleForCalendar } from '../state/schedules-helpers'

const plugins = [ timeGridPlugin, dayGridPlugin ]
// const initialView = 'timeGridWeek'
const initialView = 'dayGridMonth'

const headerSetup = {
  left: 'prev,next',
  center: 'title',
  right: 'timeGridDay,timeGridWeek,dayGridMonth'
}

const height = 750

const MonthRenderer = ({ event }) => {
  const { time, _employee, color } = event

  return (
    <>
      <div className="month-calendar__pill" style={{ backgroundColor: color }}></div>
      <span className="month-calendar__title">{ _employee.displayName }</span>
      <span className="month-calendar__time">{ time }</span>
    </>
  )
}

const eventRenderer = (eventInfo) => {
  const { type: viewType } = eventInfo.view
  const { timeText, backgroundColor, event } = eventInfo
  const { extendedProps } = event

  const e = { ...extendedProps, time: timeText, color: backgroundColor }

  if (viewType === 'dayGridMonth') return <MonthRenderer event={ e } />

  return (
    <>
      <div className="uk-margin-small-right">{eventInfo.timeText}</div>
      <div><strong>{eventInfo.event.title}</strong></div>
    </>
  )
}

const SchedulesCalendar = ({ schedules }) => {
  const history = useHistory()

  const handleClickEvent = (eventInfo) => {
    const { _id: scheduleId } = eventInfo.event.extendedProps
    history.push(`/schedules/${scheduleId}`)
  }

  return (
    <Calendar { ...{
      plugins,
      initialView,
      headerToolbar: headerSetup,
      allDaySlot: false,
      nowIndicator: true,
      events: map(formatScheduleForCalendar, schedules),
      height,
      locale: frLocale,
      eventContent: eventRenderer,
      eventClick: handleClickEvent,
    } }
    />
  )
}

export default SchedulesCalendar
