import { createSlice } from '@reduxjs/toolkit'
import { pluck, dissoc, reject, equals, merge, uniq, concat, assocPath, compose, append, assoc } from 'ramda'
import { mapIdToObj } from '../../helpers.js'

import request from '../../request'

const initialState = {
  byId: {},
  all: {
    ids: [],
    isCached: false,
    isFetching: false,
    error: null,
  }
}

const slice = createSlice({
  name: 'procedures',
  initialState,
  reducers: {
    fetching: (state, action) => {
      return assocPath([ 'all', 'isFetching' ], true, state)
    },
    notFetched: (state, action) => {
      return compose(
        assocPath([ 'all', 'error' ], action.payload),
        assocPath([ 'all', 'isFetching' ], false)
      )(state)
    },
    fetched: (state, action) => {
      const byId = mapIdToObj(action.payload)
      const ids = pluck('_id', action.payload)
      
      return {
        byId: merge(state.byId, byId),
        all: { ids: uniq(concat(state.all.ids, ids)), isCached: true, isFetching: false }
      }
    },
    fetchedOne: (state, action) => {
      const byId = assoc(action.payload._id, action.payload, state.byId)
      const ids = uniq(append(action.payload._id, state.all.ids))

      return { byId, all: assoc('ids', ids, state.all) }
    },
    hadError: (state, action) => assocPath([ 'all', 'error' ], action.payload, state),
    deletedOne: (state, action) => {
      const byId = dissoc(action.payload, state.byId)
      const ids = reject(equals(action.payload), state.all.ids)

      return { byId, all: assoc('ids', ids, state.all) }
    },
  },
})

const { fetching, fetched, fetchedOne, notFetched, deletedOne } = slice.actions

export const fetch = () => async (dispatch) => {
  try {
    dispatch(fetching())
    const { data: { procedures } } = await request.get('/procedures')

    dispatch(fetched(procedures))
  } catch (err) {
    dispatch(notFetched(err.toString()))
  }
}

export const create = (partialProcedure, onSuccess, onError) => async (dispatch) => {
  try {
    const { data: { procedure } } = await request.post('/procedures', partialProcedure)

    dispatch(fetchedOne(procedure))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const update = (procedureId, partialProcedure, onSuccess, onError) => async (dispatch, getState) => {
  try {
    const { data: { procedure } } = await request.put(`/procedures/${procedureId}`, partialProcedure)

    dispatch(fetchedOne(procedure))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const del = (procedureId, onSuccess, onError) => async (dispatch) => {
  try {
    await request.delete(`/procedures/${procedureId}`)

    dispatch(deletedOne(procedureId))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const downloadFile = (procedureId, fileId) => async (dispatch) => {
  try {
    const { data } = await request.get(`/procedures/${procedureId}/files/${fileId}`, { responseType: 'blob' })
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileId)
    document.body.appendChild(link)
    link.click()
  } catch (err) {
    console.log(err.toString())
  }
}

export const uploadFiles = (procedureId, formData) => async (dispatch) => {
  try {
    const { data: { procedure } } = await request.post(`/procedures/${procedureId}/files`, formData)
    dispatch(fetchedOne(procedure))
  } catch (e) {
    console.log(e.toString())
  }
}

export const deleteFile = (procedureId, fileId) => async (dispatch, getState) => {
  try {
    const state = getState()
    const oldProcedure = state.procedures.byId[procedureId]
    const newFiles = reject(equals(fileId), oldProcedure.files)
    const { data: { procedure } } = await request.put(`/procedures/${procedureId}`, { files: newFiles })
    dispatch(fetchedOne(procedure))
  } catch (e) {
    console.log(e.toString())
  }
}


export default slice.reducer
