import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { map, remove, append, join, reject, isNil, isEmpty } from 'ramda'

import { sliceSelector as usersSliceSelector, allSelector as usersAllSelector } from '../../users/state/users-selectors'
import { fetch as fetchUsers } from '../../users/state/users-reducer'

import { bulkCreate } from '../state/schedules-reducer'

import { pickDeepDefined } from '../../helpers'

import Layout from '../../components/layout'
import SchedulesForm from '../components/schedules-form'

const CreateScheduleScreen = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { all: { isFetching, isCached, error: usersError } } = useSelector(usersSliceSelector)
  const users = useSelector(usersAllSelector)

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetchUsers())
  }, [ dispatch, isFetching, isCached ])

  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ usersError, globalError ]))

  const [ forms, setForms ] = useState([ (formProps) => <SchedulesForm { ...formProps } key="1" i="1" /> ])
  const [ formData, setFormData ] = useState([ {} ])

  const handleAddForm = () => {
    setForms((oldList) => append((formProps) => <SchedulesForm { ...formProps } key={ oldList.length + 1 } i={ oldList.length + 1 } />, oldList))
    setFormData(append({}))
  }
  const handleSubmit = () => {
    dispatch(bulkCreate(map(pickDeepDefined, formData), () => history.push('/schedules'), setGlobalError))
  }
  const handleDelete = (i) => {
    setForms(remove(i, 1))
    setFormData(remove(i, 1))
  }
  return (
    <Layout>
      <h3>Ajouter un créneau</h3>


      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && map((fn) => fn({ users, onDelete: handleDelete, setFormData }), forms) }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-secondary" onClick={ handleAddForm }>Ajouter un créneau</button>
        <button className="uk-button uk-button-primary uk-margin-left" onClick={ handleSubmit }>Insérer</button>
      </div>
    </Layout>
  )
}

export default CreateScheduleScreen
