import React, { useState } from 'react'
import moment from 'moment'
import {
  propEq,
  defaultTo,
  isEmpty,
  add,
  groupBy,
  map,
  compose,
  uniq,
  pluck,
  toPairs,
  reduce,
  contains,
  keys,
  path,
} from 'ramda'
import NumberChart from '../../dashboard/components/charts/number-chart'

const getTargetValue = path([ 'target', 'value' ])

const getMonth = ({ plannedStart }) => moment(plannedStart).format('MMMM-YYYY')
const getYear = ({ plannedStart }) => moment(plannedStart).format('YYYY')
const getWeek = ({ plannedStart }) => moment(plannedStart).format('YYYY-w')
const getDay = ({ plannedStart }) => moment(plannedStart).format('YYYY-w-DD')

const isWeekend = (schedule) => {
  const { plannedStart } = schedule
  const day = moment(plannedStart).day()
  return contains(day, [ 0, 6 ])
}
const isEvening = (schedule) => {
  const { plannedStart } = schedule
  return moment(plannedStart).hour() >= 17
}

const isHoliday = propEq('type', 'holiday')

const splitSchedules = (schedules) => reduce((acc, schedule) => {
  if (isHoliday(schedule)) {
    acc.holidays.push(schedule)
  }
  if (isWeekend(schedule)) {
    acc.weekends.push(schedule)
    return acc
  }
  if (isEvening(schedule)) {
    acc.evenings.push(schedule)
    return acc
  }

  acc.days.push(schedule)
  return acc
}, { weekends: [], evenings: [], days: [], holidays: [] }, schedules)

const countUniqueBy = (pred, schedules) => {
  const grouped = groupBy(pred, schedules)
  return keys(grouped).length
}

const calculateTotalPlanned = (schedules) => reduce((acc, schedule) => {
  const { plannedStart, plannedEnd } = schedule
  return add(moment(plannedEnd).diff(moment(plannedStart), 'seconds'), acc)
}, 0, schedules)

const calculateTotalActual = (schedules) => reduce((acc, schedule) => {
  const { plannedStart, plannedEnd, actualStart, actualEnd } = schedule
  const start = defaultTo(plannedStart, actualStart)
  const end = defaultTo(plannedEnd, actualEnd)
  return add(moment(end).diff(moment(start), 'seconds'), acc)
}, 0, schedules)

const calculateStatistics = (schedules) => {
  const { weekends, evenings, days, holidays } = splitSchedules(schedules)
  const worked = [ ...weekends, ...evenings, ...days ]

  const actualTotalSeconds = calculateTotalActual(worked)
  const plannedTotalSeconds = calculateTotalPlanned(worked)
  return {
    planned: {
      weekends: {
        count: weekends.length,
        unique: countUniqueBy(getWeek, weekends),
        seconds: calculateTotalPlanned(weekends),
        schedules: weekends,
      },
      evenings: {
        count: evenings.length,
        unique: countUniqueBy(getDay, evenings),
        seconds: calculateTotalPlanned(evenings),
        schedules: evenings,
      },
      days: {
        count: days.length,
        unique: countUniqueBy(getDay, days),
        seconds: calculateTotalPlanned(days) - (days.length * 1800),
        schedules: days,
      },
      holidays: {
        count: holidays.length,
        unique: countUniqueBy(getDay, holidays),
        seconds: calculateTotalPlanned(holidays),
        schedules: holidays,
      },
      total: {
        count: worked.length,
        unique: countUniqueBy(getDay, worked) - (days.length * 1800),
        seconds: plannedTotalSeconds,
        schedules: worked,
      },
    },
    actual: {
      weekends: {
        count: weekends.length,
        unique: countUniqueBy(getWeek, weekends),
        seconds: calculateTotalActual(weekends),
        schedules: weekends,
      },
      evenings: {
        count: evenings.length,
        unique: countUniqueBy(getDay, evenings),
        seconds: calculateTotalActual(evenings),
        schedules: evenings,
      },
      days: {
        count: days.length,
        unique: countUniqueBy(getDay, days),
        seconds: calculateTotalActual(days) - (days.length * 1800),
        schedules: days,
      },
      holidays: {
        count: holidays.length,
        unique: countUniqueBy(getDay, holidays),
        seconds: calculateTotalActual(holidays),
        schedules: holidays,
      },
      total: {
        count: worked.length,
        unique: countUniqueBy(getDay, worked) - (days.length * 1800),
        seconds: actualTotalSeconds,
        schedules: worked,
        diff: actualTotalSeconds - plannedTotalSeconds,
      },
    },
  }
}

const createStatistics = (schedules) => {
  const byMonth = groupBy(getMonth, schedules)

  const months = reduce((acc, [ key, value ]) => {
    const statistics = calculateStatistics(value)
    acc[key] = statistics
    return acc
  }, {}, toPairs(byMonth))

  const byYear = groupBy(getYear, schedules)

  const years = reduce((acc, [ key, value ]) => {
    const statistics = calculateStatistics(value)
    acc[key] = statistics
    return acc
  }, {}, toPairs(byYear))

  return { months, years }
}

const getMonths = compose(
  uniq,
  map((date) => moment(date).format('MMMM')),
  pluck('plannedStart')
)

const getYears = compose(
  uniq,
  map((date) => moment(date).format('YYYY')),
  pluck('plannedStart')
)

const formatSecondsToString = (seconds) => {
  const duration = moment.duration(seconds, 'seconds')
  const arr = []

  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const totalHours = duration.asHours()

  if (months !== 0) {
    arr.push(`${months} mois`)
  }
  if (days !== 0) {
    arr.push(`${days} jours`)
  }
  if (hours !== 0) {
    arr.push(`${hours} heures`)
  }

  let string = arr.join(', ')

  if (!string) {
    string = `un total de ${totalHours} heures ont été`
    return string
  }

  string += `, pour un total de ${totalHours} heures`

  return string
}

const secondsToLabel = (seconds) => {
  const hours = Math.round(moment.duration(seconds, 'seconds').as('hours') * 10) / 10
  return `${hours}h`
}

const Statistics = (props) => {
  const { statistics, type } = props
  if (!statistics) return null
  const { planned, actual } = statistics

  return (
    <div className="employee-statistics__statistics-wrapper">
      <div>
        <h5>Planifié</h5>

        <div className="dashboard__charts">
          <NumberChart
            title={ `Total (${planned.total.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.total.seconds) } }
            coordinates={ { x: 0, y: 0, w: 12, h: 1 } }
          />
          <NumberChart
            title={ `Soirées (${planned.evenings.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.evenings.seconds) } }
            coordinates={ { x: 0, y: 1, w: 6, h: 1 } }
          />
          <NumberChart
            title={ `Journées et week-ends (${planned.days.count + planned.weekends.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.days.seconds + planned.weekends.seconds) } }
            coordinates={ { x: 6, y: 1, w: 6, h: 1 } }
          />
          <NumberChart
            title={ `Pause` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.days.count * 1800) } }
            coordinates={ { x: 0, y: 2, w: 6, h: 1 } }
          />
          <NumberChart
            title={ `Vacances (${planned.holidays.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.holidays.seconds) } }
            coordinates={ { x: 6, y: 2, w: 6, h: 1 } }
          />
        </div>
      </div>
      <div>
        <h5>Réalisé</h5>

        <div className="dashboard__charts">
          <NumberChart
            title={ `Total (${actual.total.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(actual.total.seconds) } }
            coordinates={ { x: 0, y: 0, w: 8, h: 1 } }
          />
          <NumberChart
            title={ `Solde` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(actual.total.diff) } }
            coordinates={ { x: 8, y: 0, w: 4, h: 1 } }
          />
          <NumberChart
            title={ `Soirées (${actual.evenings.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(actual.evenings.seconds) } }
            coordinates={ { x: 0, y: 1, w: 6, h: 1 } }
          />
          <NumberChart
            title={ `Journées et week-ends (${actual.days.count + actual.weekends.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(actual.days.seconds + actual.weekends.seconds) } }
            coordinates={ { x: 6, y: 1, w: 6, h: 1 } }
          />
          <NumberChart
            title={ `Pause` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.days.count * 1800) } }
            coordinates={ { x: 0, y: 2, w: 6, h: 1 } }
          />
          <NumberChart
            title={ `Vacances (${actual.holidays.count})` }
            dimensions={ [] }
            serie={ { total: secondsToLabel(planned.holidays.seconds) } }
            coordinates={ { x: 6, y: 2, w: 6, h: 1 } }
          />
        </div>
      </div>
    </div>
  )
}

const EmployeeStatistics = (props) => {
  const { schedules } = props

  const today = moment()

  const [ year, setYear ] = useState(today.format('YYYY'))
  const [ month, setMonth ] = useState('')

  const type = isEmpty(month) ? 'year': 'month'
  const typeProp = type === 'month' ? `${month}-${year}` : year

  const monthLabels = getMonths(schedules)
  const yearLabels = getYears(schedules)

  const statistics = createStatistics(schedules)

  const viewStats = statistics[`${type}s`][typeProp]
  const viewLabel = type === 'month' ? month : year

  return (
    <div className="employee-statistics">
      <h1>Statistiques</h1>

      <div className="employee-statistics__date-selector">
        <select value={ month } onChange={ compose(setMonth, getTargetValue) }>
          <option value="">Aucun (Toute l'année)</option>
          { map((label) => (
            <option key={ label } value={ label }>{ label }</option>
          ), monthLabels) }
        </select>
        <select value={ year } onChange={ compose(setYear, getTargetValue) }>
          { map((label) => (
            <option key={ label } value={ label }>{ label }</option>
          ), yearLabels) }
        </select>
      </div>

      <Statistics statistics={ viewStats } label={ viewLabel } type={ type } />
    </div>
  )
}

export default EmployeeStatistics
