import React, {useState, useRef} from 'react'
import { not } from 'ramda'
import moment from 'moment'

import { DateRangePicker as Picker } from 'react-date-range'
import { frCH as locale } from 'date-fns/locale'
import {SERVER_DATE_FORMAT, DATE_FORMAT} from '../helpers'
import useOutsideClick from './use-outside-click'

const defaultDateRange = {
  startDate: moment().startOf('year').format(SERVER_DATE_FORMAT),
  endDate: moment().endOf('year').format(SERVER_DATE_FORMAT),
}

const getLabel = (momentDate) => momentDate.format(DATE_FORMAT)

const DateRangePicker = (props) => {
  const {
    dateRange = defaultDateRange,
    onChange = () => {},
  } = props

  const [ show, setShow ] = useState(false)
  const pickerRef = useRef()

  const dateRangeMoment = {
    startDate: moment(dateRange.startDate, SERVER_DATE_FORMAT),
    endDate: moment(dateRange.endDate, SERVER_DATE_FORMAT),
  }
  const dateRangeDate = {
    startDate: dateRangeMoment.startDate.toDate(),
    endDate: dateRangeMoment.endDate.toDate(),
    key: 'selection',
  }

  useOutsideClick(pickerRef, () => {
    if (show) setShow(false)
  })

  const handleClickTrigger = () => setShow(not)
  const handleSelect = ({ selection }) => {
    const { startDate, endDate } = selection
    onChange({
      startDate: moment(startDate).format(SERVER_DATE_FORMAT),
      endDate: moment(endDate).format(SERVER_DATE_FORMAT),
    })
  }

  return (
    <div className="date-range-picker">
      <div className="date-range-picker__trigger" onClick={ handleClickTrigger }>
        <span>{ getLabel(dateRangeMoment.startDate) } → { getLabel(dateRangeMoment.endDate) }</span>
      </div>
      { show &&
        <div className="date-range-picker__picker" ref={ pickerRef }>
          <Picker
            onChange={ handleSelect }
            ranges={ [ dateRangeDate ] }
            months={ 1 }
            locale={ locale }
            showSelectionPreview={ true }
            moveRangeOnFirstSelection={ false }
          />
        </div>
      }
    </div>
  )
}

export default DateRangePicker
