import Cookies from 'universal-cookie'
import { createSlice } from '@reduxjs/toolkit'
import { merge } from 'ramda'

import request from '../../request'

const cookies = new Cookies()

const initialState = {
  isAuthenticated: false,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialized: (state, action) => {
      return merge(state, action.payload)
    },
    login: (state, action) => {
      return { isAuthenticated: true, user: action.payload }
    },
    logout: (state, action) => {

      return { isAuthenticated: false }
    }
  },
})

const { login: commitLogin, initialized: commitInitialized, logout: commitLogout } = slice.actions

export const logout = (onError, onSuccess) => {
  return async (dispatch) => {
    delete request.defaults.headers.common['Authorization'] 
    cookies.remove('authToken')

    dispatch(commitLogout())
    onSuccess()
  }
}

export const initialized = (onError, onSuccess) => {
  return async (dispatch) => {
    try {
      const token = cookies.get('authToken')
      if (!token) {
        onSuccess()
        return dispatch(commitInitialized({}))
      }

      request.defaults.headers.common['Authorization'] = token
      const { data: { user } } = await request.get('/profile')
      if (!user) {
        delete request.defaults.headers.common['Authorization']
        onSuccess()
        return dispatch(commitInitialized({}))
      }

      dispatch(commitInitialized({ isAuthenticated: true, user }))
      onSuccess()
    } catch (error) {
      delete request.defaults.headers.common['Authorization']
      cookies.remove('authToken')

      dispatch(commitInitialized({}))
      onError()

      return error
    }
  }
}

export const login = (payload, onError, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data: { user, token } } = await request.post('/login', payload)

      request.defaults.headers.common['Authorization'] = token
      cookies.set('authToken', token, { sameSite: 'strict' })
      
      dispatch(commitLogin(user))
      onSuccess(user)
    } catch ({ response: { data: { error } } }) {
      onError(error)
      return error
    }
  }
}

export default slice.reducer