import React, { useState, useEffect } from 'react'
import { path, compose, defaultTo, isNil, dissoc } from 'ramda'
import { pickDeepDefined } from '../../helpers'

const getTargetValue = path([ 'target', 'value' ])
const pathOr = (f, p, o) => defaultTo(f, path(p, o))

const ClientForm = (props) => {
  const { initialFormData, setFormData } = props

  const [ firstName, setFirstName ] = useState(pathOr('', [ 'firstName' ], initialFormData))
  const [ lastName, setLastName ] = useState(pathOr('', [ 'lastName' ], initialFormData))
  const [ abbreviation, setAbbreviation ] = useState(pathOr('', [ 'abbreviation' ], initialFormData))
  const [ email, setEmail ] = useState(pathOr('', [ 'email' ], initialFormData))
  const [ password, setPassword ] = useState('')
  const [ addressLine, setAddressLine ] = useState(pathOr('', [ 'address', 'line' ], initialFormData))
  const [ phone, setPhone ] = useState(pathOr('', [ 'phone' ], initialFormData))
  const [ zip, setZip ] = useState(pathOr('', [ 'address', 'zip' ], initialFormData))
  const [ city, setCity ] = useState(pathOr('', [ 'address', 'city' ], initialFormData))
  const [ country, setCountry ] = useState(pathOr('', [ 'address', 'country' ], initialFormData))

  const isEdit = !isNil(initialFormData)
  
  useEffect(() => {
    const payload = pickDeepDefined({
      firstName,
      lastName,
      abbreviation,
      email,
      password,
      phone,
      address: { line: addressLine, zip, city, country },
    })
    setFormData(isEdit ? dissoc('password', payload) : payload)
  }, [ firstName, lastName, abbreviation, email, password, addressLine, zip, city, country, phone, isEdit, setFormData ])

  return (
    <form className="uk-form-horizontal">
      <div className="uk-margin">
        <label className="uk-form-label">Prénom *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ firstName } onChange={ compose(setFirstName, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Nom *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ lastName } onChange={ compose(setLastName, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Abréviation</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ abbreviation } onChange={ compose(setAbbreviation, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Email *</label>
        <div className="uk-form-controls">
          <input type="email" className="uk-input" value={ email } onChange={ compose(setEmail, getTargetValue) } />
        </div>
      </div>

      { !isEdit &&
        <div className="uk-margin">
          <label className="uk-form-label">Mot de passe *</label>
          <div className="uk-form-controls">
            <input type="password" className="uk-input" value={ password } onChange={ compose(setPassword, getTargetValue) } />
          </div>
        </div>
      }

      <div className="uk-margin">
        <label className="uk-form-label">Numéro de téléphone</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" placeholder="+41 012 345 67 89" value={ phone } onChange={ compose(setPhone, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Adresse</label>
        <div className="uk-form-controls uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1 uk-padding-remove-left">
            <input type="text" className="uk-input" placeholder="Adresse" value={ addressLine } onChange={ compose(setAddressLine, getTargetValue) } />
          </div>
          <div className="uk-width-1-2 uk-padding-remove-left">
            <input type="text" className="uk-input" placeholder="Code postal" value={ zip } onChange={ compose(setZip, getTargetValue) } />
          </div>
          <div className="uk-width-1-2">
            <input type="text" className="uk-input" placeholder="Ville" value={ city } onChange={ compose(setCity, getTargetValue) } />
          </div>
          <div className="uk-width-1-1 uk-padding-remove-left">
            <input type="text" className="uk-input" placeholder="Pays" value={ country } onChange={ compose(setCountry, getTargetValue) } />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ClientForm
