import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { identity, isNil } from 'ramda'

import { sliceSelector, itemSelector } from '../state/schedules-selectors'
import { fetch, del } from '../state/schedules-reducer'

import Layout from '../../components/layout'

const ScheduleDetailsScreen = (props) => {
  const { match: { params: { scheduleId } } } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const schedule = useSelector(itemSelector(scheduleId))

  useEffect(() => {
    if (!isCached) dispatch(fetch()) 
  }, [ dispatch, isCached ])

  const hasError = !isNil(error) || !schedule
  const layoutProps = { isLoading: isFetching, hasError, error }

  if (isFetching || hasError) return <Layout { ...layoutProps } />

  const handleEdit = () => history.push(`/schedules/${schedule._id}/edit`)
  const handleDelete = () => {
    dispatch(del(scheduleId, () => history.push('/schedules'), identity))
  }

  return (
    <Layout { ...layoutProps }>
      <h3>Créneau du { moment(schedule.plannedStart).format('D MMMM YYYY') } <span className="uk-text-light">({ moment(schedule.plannedStart).format('HH:mm') } à { moment(schedule.plannedEnd).format('HH:mm') })</span></h3>
      
      <div data-uk-grid>
        <div className="uk-width-2-3@m uk-margin-small-top">
          <h4 className="uk-heading-line"><span>Informations</span></h4>
          <table className="uk-table uk-table-divider">
            <tbody>
              <tr>
                <td className="uk-text-bold">Employé</td>
                <td className="uk-text-left uk-table-expand">{ schedule._employee.displayName }</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="uk-width-1-3@m">
          <button className="uk-button uk-button-secondary uk-width-1-2" onClick={ handleEdit }>Modifier</button>
          <button className="uk-button uk-button-danger uk-width-1-2" onClick={ handleDelete }>Supprimer</button>
        </div>
      </div>
    </Layout>  
  )
}

export default ScheduleDetailsScreen
