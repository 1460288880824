import { reduce, uniq, append, assoc } from 'ramda'

export const getCategories = reduce((acc, item) => {
  const { categories } = item
  return uniq(append(categories[0], acc))
}, [])

export const getCategoryTree = reduce((acc, item) => {
  const { categories } = item
  const accForCategory = acc[categories[0]] || []
  const newAccForCategory = uniq(append(categories[1], accForCategory))
  return assoc(categories[0], newAccForCategory, acc)
}, {})

export const getSubCategoriesForCategory = (category, list) => reduce((acc, item) => {
  const { categories } = item
  if (categories[0] !== category) return acc
  return uniq(append(categories[1], acc))
}, [], list)

export const getProceduresForCategories = (category, subCategory, list) => reduce((acc, item) => {
  const { categories } = item
  if (categories[0] !== category || categories[1] !== subCategory) return acc
  return uniq(append(item, acc))
}, [], list)