import { createSelector } from '@reduxjs/toolkit'
import { reduce, prop } from 'ramda'

export const sliceSelector = (state) => state.procedures
export const byIdMapSelector = (state) => state.procedures.byId
export const allIdsSelector = (state) => state.procedures.all.ids
export const itemSelector = (id) => (state) => state.procedures.byId[id]

export const allSelector = createSelector(
  byIdMapSelector,
  allIdsSelector,
  (idMap, ids) => reduce((acc, id) => {
    const item = prop(id, idMap)
    if (!item) return acc
    
    acc.push(item)
    return acc
  }, [], ids)
)