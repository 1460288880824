import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import UIKit from 'uikit'

import Icons from 'uikit/dist/js/uikit-icons'

import 'uikit/dist/css/uikit.min.css'
import './app.styles.scss'

import { authSelector } from './auth/state/auth-selectors'
import { initialized } from './auth/state/auth-reducer'
import ProtectedRoute from './components/protected-route'

import Navigation from './components/navigation'

import LoginScreen from './auth/screens/login-screen'
import LogoutScreen from './auth/screens/logout-screen'

import DashboardScreen from './dashboard/screens/dashboard-screen'

import BookingsScreen from './bookings/screens/bookings-screen'
import BookingDetailsScreen from './bookings/screens/booking-details-screen'
import CreateBookingScreen from './bookings/screens/create-booking-screen'
import EditBookingScreen from './bookings/screens/edit-booking-screen'

import SchedulesScreen from './schedules/screens/schedules-screen'
import CreateScheduleScreen from './schedules/screens/create-schedule-screen'
import ScheduleDetailsScreen from './schedules/screens/schedule-details-screen'
import EditScheduleScreen from './schedules/screens/edit-schedule-screen'

import AlertsOverview from './alerts/screens/alerts-overview'
import AlertDetailsScreen from './alerts/screens/alert-details-screen'

import ProceduresScreen from './procedures/screens/procedures-screen'
import ProcedureDetailsScreen from './procedures/screens/procedure-details-screen'
import CreateProcedureScreen from './procedures/screens/create-procedure-screen'
import EditProcedureScreen from './procedures/screens/edit-procedure-screen'

import ClientsScreen from './clients/screens/clients-screen'
import ClientDetailsScreen from './clients/screens/client-details-screen'
import CreateClientScreen from './clients/screens/create-client-screen'
import EditClientScreen from './clients/screens/edit-client-screen'

import EmployeesScreen from './employees/screens/employees-screen'
import EmployeeDetailsScreen from './employees/screens/employee-details-screen'
import CreateEmployeeScreen from './employees/screens/create-employee-screen'
import EditEmployeeScreen from './employees/screens/edit-employee-screen'

UIKit.use(Icons)

const App = () => {
  const dispatch = useDispatch()
  const auth = useSelector(authSelector)

  const [ isInitialized, setIsInitialized ] = useState(false)

  useEffect(() => {
    const onSuccess = () => {
      setIsInitialized(true)
    }
    dispatch(initialized(onSuccess, onSuccess))
  }, [ dispatch ])

  if (!isInitialized) return null

  if (!auth.isAuthenticated) {
    return (
      <Switch>
        <Route path="/login" component={ LoginScreen } />

        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    )
  }

  return (
    <div className="app">
      <Navigation />

      <Switch>
        <Route path="/logout" component={ LogoutScreen } />

        <ProtectedRoute auth={ auth } path="/dashboard" exact component={ DashboardScreen } />

        <ProtectedRoute auth={ auth } path="/bookings" exact component={ BookingsScreen } />
        <ProtectedRoute auth={ auth } path="/bookings/new" exact component={ CreateBookingScreen } />
        <ProtectedRoute auth={ auth } path="/bookings/:bookingId/edit" exact component={ EditBookingScreen } />
        <ProtectedRoute auth={ auth } path="/bookings/:bookingId" component={ BookingDetailsScreen } />

        <ProtectedRoute auth={ auth } path="/clients" exact component={ ClientsScreen } />
        <ProtectedRoute auth={ auth } path="/clients/new" exact component={ CreateClientScreen } />
        <ProtectedRoute auth={ auth } path="/clients/:clientId/edit" component={ EditClientScreen } />
        <ProtectedRoute auth={ auth } path="/clients/:clientId" component={ ClientDetailsScreen } />

        <ProtectedRoute auth={ auth } path="/schedules" exact component={ SchedulesScreen } />
        <ProtectedRoute auth={ auth } path="/schedules/new" exact component={ CreateScheduleScreen } />
        <ProtectedRoute auth={ auth } path="/schedules/:scheduleId/edit" exact component={ EditScheduleScreen } />
        <ProtectedRoute auth={ auth } path="/schedules/:scheduleId" exact component={ ScheduleDetailsScreen } />

        <ProtectedRoute auth={ auth } path="/alerts" exact component={ AlertsOverview } />
        <ProtectedRoute auth={ auth } path="/alerts/:alertId" component={ AlertDetailsScreen } />

        <ProtectedRoute auth={ auth } path="/procedures" exact component={ ProceduresScreen } />
        <ProtectedRoute auth={ auth } path="/procedures/new" exact component={ CreateProcedureScreen } />
        <ProtectedRoute auth={ auth } path="/procedures/:procedureId/edit" exact component={ EditProcedureScreen } />
        <ProtectedRoute auth={ auth } path="/procedures/:procedureId" exact component={ ProcedureDetailsScreen } />

        <ProtectedRoute auth={ auth } path="/employees" exact component={ EmployeesScreen } />
        <ProtectedRoute auth={ auth } path="/employees/new" exact component={ CreateEmployeeScreen } />
        <ProtectedRoute auth={ auth } path="/employees/:employeeId/edit" component={ EditEmployeeScreen } />
        <ProtectedRoute auth={ auth } path="/employees/:employeeId" component={ EmployeeDetailsScreen } />
      </Switch>
    </div>
  )
}

export default App
