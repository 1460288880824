import { createSlice } from '@reduxjs/toolkit'
import { reduce, has, omit, forEach, path, pluck, merge, uniq, concat, assocPath, compose, append, assoc, dissoc, reject, equals } from 'ramda'
import moment from 'moment'
import { Promise } from 'bluebird'

import { fetch as fetchUsers } from '../../users/state/users-reducer'

import { denormalizeEntities, denormalizeEntity, mapIdToObj, SERVER_DATETIME_FORMAT } from '../../helpers.js'
import request from '../../request'

const initialState = {
  byId: {},
  all: {
    ids: [],
    isCached: false,
    isFetching: false,
    error: null,
  }
}

const slice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    fetching: (state, action) => {
      return assocPath([ 'all', 'isFetching' ], true, state)
    },
    notFetched: (state, action) => {
      return compose(
        assocPath([ 'all', 'error' ], action.payload),
        assocPath([ 'all', 'isFetching' ], false)
      )(state)
    },
    fetched: (state, action) => {
      const byId = mapIdToObj(action.payload)
      const ids = pluck('_id', action.payload)
      
      return {
        byId: merge(state.byId, byId),
        all: { ids: uniq(concat(state.all.ids, ids)), isCached: true, isFetching: false }
      }
    },
    fetchedOne: (state, action) => {
      const byId = assoc(action.payload._id, action.payload, state.byId)
      const ids = uniq(append(action.payload._id, state.all.ids))

      return { byId, all: assoc('ids', ids, state.all) }
    },
    deletedOne: (state, action) => {
      const byId = dissoc(action.payload, state.byId)
      const ids = reject(equals(action.payload), state.all.ids)

      return { byId, all: assoc('ids', ids, state.all) }
    },
    hadError: (state, action) => assocPath([ 'all', 'error' ], action.payload, state)
  },
})

const { fetching, fetched, notFetched, fetchedOne, deletedOne } = slice.actions

export const fetch = () => async (dispatch, getState) => {
  try {
    dispatch(fetching())

    const initialState = getState()
    const { users: { all: { isCached: isUsersCached } } } = initialState
    if (!isUsersCached) {
      await fetchUsers()(dispatch, getState)
    }

    const query = { start: '2020-01-01', end: '2025-12-31' }
    const { data: { schedules } } = await request.get('/schedules', { params: query })

    const state = getState()
    const denormalized = denormalizeEntities(state, schedules)

    dispatch(fetched(denormalized))
  } catch (err) {
    dispatch(notFetched(err.toString()))
  }
}

const splitScheduleDates = (schedules) => reduce((acc, schedule) => {
  if (has('dates', schedule)) {
    const { dates, startTime, endTime, type } = schedule
    const template = omit([ 'dates', 'startTime', 'endTime' ], schedule)

    const plannedStartTime = type === 'holiday'
      ? '06:00'
      : startTime
    const plannedEndTime = type === 'holiday'
      ? '18:00'
      : endTime

    forEach((date) => {
      const plannedStart = moment(`${date}T${plannedStartTime}`, 'YYYY-MM-DD[T]HH:mm').format(SERVER_DATETIME_FORMAT)
      const plannedEnd = moment(`${date}T${plannedEndTime}`, 'YYYY-MM-DD[T]HH:mm').format(SERVER_DATETIME_FORMAT)
      
      const item = merge(template, { plannedStart, plannedEnd })
      acc.push(item)
    }, dates)
  } else {
    acc.push(schedule)
  }

  return acc
}, [], schedules)

export const bulkCreate = (partialSchedules, onSuccess, onError) => async (dispatch, getState) => {
  try {
    const splitSchedules = splitScheduleDates(partialSchedules)
    const schedules = await Promise.map(splitSchedules, (partialSchedule) => {
      return request.post('/schedules', partialSchedule)
        .then(path([ 'data', 'schedule' ]))
    })

    const initialState = getState()
    const { users: { all: { isCached: isUsersCached } } } = initialState
    if (!isUsersCached) {
      await fetchUsers()(dispatch, getState)
    }

    const state = getState()
    const denormalized = denormalizeEntities(state, schedules)

    dispatch(fetched(denormalized))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const update = (scheduleId, partialSchedule, onSuccess, onError) => async (dispatch, getState) => {
  try {
    const scheduleData = splitScheduleDates([ partialSchedule ])[0]
    const { data: { schedule } } = await request.put(`/schedules/${scheduleId}`, scheduleData)

    const initialState = getState()
    const { users: { all: { isCached: isUsersCached } } } = initialState
    if (!isUsersCached) {
      await fetchUsers()(dispatch, getState)
    }

    const state = getState()
    const denormalized = denormalizeEntity(state, schedule)

    dispatch(fetchedOne(denormalized))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const del = (scheduleId, onSuccess, onError) => async (dispatch) => {
  try {
    await request.delete(`/schedules/${scheduleId}`)

    dispatch(deletedOne(scheduleId))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export default slice.reducer
