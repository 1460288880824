import React, {useState, useRef} from 'react'
import {Calendar} from 'react-date-range'
import { not } from 'ramda'
import moment from 'moment'
import { frCH } from 'date-fns/locale'

import {SERVER_DATE_FORMAT, DATE_FORMAT} from '../helpers'
import useOutsideClick from './use-outside-click'

const today = moment().format(SERVER_DATE_FORMAT)

const DateInput = (props) => {
  const { value = today, onChange } = props

  const momentDate = moment(value, SERVER_DATE_FORMAT)
  const date = momentDate.toDate()

  const calendarRef = useRef()
  const [ showCalendar, setShowCalendar ] = useState(false)

  useOutsideClick(calendarRef, () => {
    if (showCalendar) setShowCalendar(false)
  })

  const handleToggleCalendar = () =>
    setShowCalendar(not)

  const handleSelectDate = (date) =>
    onChange(moment(date).format(SERVER_DATE_FORMAT))

  return (
    <div className="date-input">
      <span onClick={ handleToggleCalendar }>{ momentDate.format(DATE_FORMAT) }</span>

      { showCalendar &&
        <div ref={ calendarRef }>
          <Calendar date={ date } locale={ frCH } onChange={ handleSelectDate } />
        </div>
      }
    </div>
  )
}

export default DateInput
