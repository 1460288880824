import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { all, identity, join, reject, isNil, isEmpty } from 'ramda'

import { sliceSelector as usersSliceSelector, allSelector as usersAllSelector } from '../../users/state/users-selectors'
import { fetch as fetchUsers } from '../../users/state/users-reducer'
import { sliceSelector, itemSelector } from '../state/schedules-selectors'
import { fetch, update } from '../state/schedules-reducer'

import { pickDeepDefined } from '../../helpers'

import Layout from '../../components/layout'
import SchedulesForm from '../components/schedules-form'

const EditScheduleScreen = (props) => {
  const { match: { params: { scheduleId } } } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { all: { isFetching: isUsersFetching, isCached: isUsersCached, error: usersError } } = useSelector(usersSliceSelector)
  const { all: { isFetching: isSchedulesFetching, isCached: isSchedulesCached, error: schedulesError } } = useSelector(sliceSelector)
  const users = useSelector(usersAllSelector)
  const schedule = useSelector(itemSelector(scheduleId))

  useEffect(() => {
    if (!isUsersCached && !isUsersFetching) dispatch(fetchUsers())
    if (!isSchedulesCached && !isSchedulesFetching) dispatch(fetch())
  }, [ dispatch, isUsersCached, isUsersFetching, isSchedulesCached, isSchedulesFetching])

  const isFetching = all(identity, [ isUsersFetching, isSchedulesFetching ])
  const isCached = all(identity, [ isUsersCached, isSchedulesCached ])
  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ usersError, schedulesError, globalError ]))

  const [ formData, setFormData ] = useState([ {} ])

  const handleSubmit = () => {
    dispatch(update(scheduleId, pickDeepDefined(formData[0]), () => history.push('/schedules'), setGlobalError))
  }
  return (
    <Layout>
      <h3>Modifier le créneau</h3>


      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && !isFetching &&
        <SchedulesForm { ...{ users, initialFormData: schedule, i: 1, setFormData } } />
      }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" disabled={ isFetching } onClick={ handleSubmit }>Modifier</button>
      </div>
    </Layout>
  )
}

export default EditScheduleScreen