import moment from 'moment'
import { propOr } from 'ramda'

const CALENDAR_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'

export const formatScheduleForCalendar = (schedule) => {
  const { plannedStart, plannedEnd, _employee } = schedule

  return {
    start: moment(plannedStart).format(CALENDAR_DATETIME_FORMAT),
    end: moment(plannedEnd).format(CALENDAR_DATETIME_FORMAT),
    title: _employee.displayName,
    extendedProps: schedule,
    color: propOr('#00FF00', 'color', _employee),
  }
}
