import React from 'react'

const Layout = ({ isLoading, hasError, error, className = '', children }) => {
  return (
    <>
      <div className="content">
        { isLoading &&
          <div data-uk-spinner />
        }
        { !isLoading && hasError &&
          <div className="uk-alert-danger" data-uk-alert>
            <p>{ error }</p>
          </div>
        }
        { !isLoading && !hasError &&
          <div className={ className }>
            { children }
          </div>
        }
      </div>
    </>  
  )
}

export default Layout
