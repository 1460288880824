import React from 'react'
import { useHistory } from 'react-router-dom'

import DataTable from '../../components/data-table'

const Row = ({ handleClickEmployee }) => ({ item: employee }) => {
  const { firstName, lastName, email } = employee
  return (
    <tr onClick={ handleClickEmployee(employee._id) }>
      <td></td>
      <td>{ firstName }</td>
      <td>{ lastName }</td>
      <td>{ email }</td>
    </tr>
  )
}

const EmployeesTable = ({ employees }) => {
  const history = useHistory()

  const headings = [
    { label: 'Prénom', prop: [ 'firstName' ], weight: 0.9, searchable: true },
    { label: 'Nom', prop: [ 'lastName' ], searchable: true },
    { label: 'Email', prop: [ 'email' ], searchable: true },
  ]

  const handleClickEmployee = (clientId) => () => {
    history.push(`/employees/${clientId}`)
  }

  return (
    <DataTable
      list={ employees }
      headings={ headings }
      rowRenderer={ Row({ handleClickEmployee }) }
      initialSortIndex={ 0 }
      initialSortOrder="asc"
      searchable={ true }
    />
  )
}

export default EmployeesTable
