import axios from 'axios'

const { REACT_APP_BACKEND } = process.env

const baseURL = REACT_APP_BACKEND === 'production'
  ? 'https://api.cazard.ektinmedia.se'
  : 'http://127.0.0.1:3001'

const request = axios.create({
  baseURL,
})

export default request
