import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { identity } from 'ramda'

import { logout } from '../state/auth-reducer'

const LogoutScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const onSuccess = () => {
    history.push('/login')
  }

  useEffect(() => {
    dispatch(logout(identity, onSuccess))
  })

  return (
    <div>Déconnexion...</div>
  )
}

export default LogoutScreen