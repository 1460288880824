import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import moment from 'moment'
import 'moment/locale/fr'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import store from './state/store'

import App from './App'
import * as serviceWorker from './serviceWorker'

moment.locale('fr')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
