import React from 'react'
import { append, findIndex, remove, map } from 'ramda'
import moment from 'moment'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import { DateUtils } from 'react-day-picker'

import {SERVER_DATE_FORMAT, DATE_FORMAT} from '../helpers'

import 'moment/locale/fr-ch'

const today = moment().format(SERVER_DATE_FORMAT)

const formatDatesToStrings = map((value) => moment(value).format(SERVER_DATE_FORMAT))
const formatMomentsToDates = map((value) => value.toDate())

const MultiDateInput = (props) => {
  const { value = [ today ], onChange } = props

  const momentDates = map((v) => moment(v, SERVER_DATE_FORMAT), value)
  const dates = formatMomentsToDates(momentDates)

  const handleDayClick = (day, { selected }) => {
    if (selected) {
      if (dates.length === 1) return

      const i = findIndex((d) =>
        DateUtils.isSameDay(d, day)
      , dates)
      onChange(formatDatesToStrings(remove(i, 1, dates)))
    } else {
      onChange(formatDatesToStrings(append(day, dates)))
    }
  }

  const label = momentDates.length === 1
    ? momentDates[0].format(DATE_FORMAT)
    : `${momentDates.length} dates sélectionnées`

  return (
    <div className="multi-date-input">
      <DayPickerInput
        dayPickerProps={ {
          selectedDays: dates,
          onDayClick: handleDayClick,
          showOutsideDays: true,
          firstDayOfWeek: 1,
          localeUtils: MomentLocaleUtils,
          locale: 'fr-ch'
        } }
        hideOnDayClick={ false }
        value={ label }
      />
    </div>
  )
}

export default MultiDateInput
