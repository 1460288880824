import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { map, remove, append, all, identity, join, reject, isNil, isEmpty } from 'ramda'

import { sliceSelector as clientsSliceSelector, allSelector as clientsAllSelector } from '../../clients/state/clients-selectors'
import { fetch as fetchClients } from '../../clients/state/clients-reducer'
import { sliceSelector as venuesSliceSelector, allSelector as venuesAllSelector } from '../../venues/state/venues-selectors'
import { fetch as fetchVenues } from '../../venues/state/venues-reducer'

import { bulkCreate } from '../state/bookings-reducer'

import { pickDeepDefined } from '../../helpers'

import Layout from '../../components/layout'
import BookingsForm from '../components/bookings-form'

const CreateBookingScreen = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { all: { isFetching: isClientsFetching, isCached: isClientsCached, error: clientsError } } = useSelector(clientsSliceSelector)
  const { all: { isFetching: isVenuesFetching, isCached: isVenuesCached, error: venuesError } } = useSelector(venuesSliceSelector)
  const clients = useSelector(clientsAllSelector)
  const venues = useSelector(venuesAllSelector)

  useEffect(() => {
    if (!isClientsCached && !isClientsFetching) dispatch(fetchClients())
    if (!isVenuesCached && !isVenuesFetching) dispatch(fetchVenues())
  }, [ dispatch, isClientsCached, isClientsFetching, isVenuesCached, isVenuesFetching ])

  const isFetching = all(identity, [ isClientsFetching, isVenuesFetching ])
  const isCached = all(identity, [ isClientsCached, isVenuesCached ])
  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ clientsError, venuesError, globalError ]))

  const [ forms, setForms ] = useState([ (formProps) => <BookingsForm { ...formProps } key="1" i="1" /> ])
  const [ formData, setFormData ] = useState([ {} ])

  const handleAddForm = () => {
    setForms((oldList) => append((formProps) => <BookingsForm { ...formProps } key={ oldList.length + 1 } i={ oldList.length + 1 } />, oldList))
    setFormData(append({}))
  }

  const handleSubmit = () => {
    dispatch(bulkCreate(map(pickDeepDefined, formData), () => history.push('/bookings', { viewType: 'calendar' }), setGlobalError))
  }
  const handleDelete = (i) => {
    setForms(remove(i, 1))
    setFormData(remove(i, 1))
  }
  return (
    <Layout>
      <h3>Créer une réservation</h3>


      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && map((fn) => fn({ clients, venues, onDelete: handleDelete, setFormData }), forms) }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-secondary" onClick={ handleAddForm }>Ajouter une réservation</button>
        <button className="uk-button uk-button-primary uk-margin-left" onClick={ handleSubmit }>Créer</button>
      </div>
    </Layout>
  )
}

export default CreateBookingScreen
