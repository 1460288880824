import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveCalendar as Calendar } from '@nivo/calendar'

import Layout from '../../components/layout'
import { defaultTo, times, map, sortBy, prop, find, propEq, isEmpty } from 'ramda'
import { formatCurrency, SERVER_DATE_FORMAT } from '../../helpers'

import { sliceSelector as venueSliceSelector, allSelector as venueAllSelector } from '../../venues/state/venues-selectors'
import { fetch as fetchVenues } from '../../venues/state/venues-reducer'
import { userSelector } from '../../auth/state/auth-selectors'

import request from '../../request'
import DateRangePicker from '../../components/date-range-picker'
import NumberChart from '../components/charts/number-chart'
import LineChart from '../components/charts/line-chart'
const calendarStart = moment('2020-05-01', 'YYYY-MM-DD')
const fakeCalendarData = times((n) => ({ day: calendarStart.clone().add(n, 'days').format('YYYY-MM-DD'), value: Math.floor(Math.random() * 30) }), 250)

const chartTypeComponentMap = {
  number: NumberChart,
  line: LineChart,
}

const monthlyToSeries = (aggregationResult) => {
  const { groups } = aggregationResult
  return [
    {
      id: 'Total',
      data: sortBy(prop('x'), map((group) => ({ x: group.key, y: group.total }), groups))
    }
  ]
}

const venueToSeries = (venues, aggregationResult) => {
  const { groups } = aggregationResult

  return map((group) => ({
    id: find(propEq('_id', group.key), venues).name,
    data: sortBy(prop('x'), map((g) => ({ x: g.key, y: g.total }), group.groups)),
  }), groups)
}

const venueOccupancyToSeries = (venues, aggregationResult) => {
  const { groups } = aggregationResult

  return map((group) => ({
    id: find(propEq('_id', group.key), venues).name,
    data: sortBy(prop('x'), map((g) => ({ x: g.key, y: Math.floor((g.total / 30) * 100) }), group.groups)),
  }), groups)
}

const DashboardScreen = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { all: { isCached } } = useSelector(venueSliceSelector)
  const venues = useSelector(venueAllSelector)
  const user = useSelector(userSelector)

  useEffect(() => {
    if (!isCached) dispatch(fetchVenues()) 
  }, [ dispatch, isCached ])

  const defaultDateRange = {
    startDate: moment().startOf('year').format(SERVER_DATE_FORMAT),
    endDate: moment().endOf('year').format(SERVER_DATE_FORMAT),
  }
  const [ dateRange, setDateRange ] = useState(defaultDateRange)

  useEffect(() => {
    const { location: { state } } = props
    setDateRange({
      startDate: defaultTo(defaultDateRange.startDate, state?.startDate),
      endDate: defaultTo(defaultDateRange.endDate, state?.endDate),
    })
  }, [ props.location.state ])

  const handleChangeDateRange = (newDateRange) => {
    const { match: { path } } = props
    history.push({
      pathname: path,
      state: newDateRange,
    })
  }
  const periodDays = moment(dateRange.endDate).diff(dateRange.startDate, 'days')

  const [ totalRevenue, setTotalRevenue ] = useState()
  const [ monthRevenueSeries, setMonthRevenueSeries ] = useState([])
  const [ venueRevenueSeries, setVenueRevenueSeries ] = useState([])
  const [ totalOccupancy, setTotalOccupancy ] = useState()
  const [ venueOccupancySeries, setVenueOccupancySeries ] = useState([])

  const [ invoiceStatistics, setInvoiceStatistics ] = useState({})
  const [ revenue, setRevenue ] = useState({ previous: 0, current: 0, next: 0 }) 

  useEffect(() => {
    async function fetch () {
      const previousPeriodQuery = {
        start: moment(dateRange.startDate).subtract(periodDays, 'days').format(SERVER_DATE_FORMAT),
        end: moment(dateRange.endDate).subtract(periodDays, 'days').format(SERVER_DATE_FORMAT),
      }
      const periodQuery = {
        start: moment(dateRange.startDate).format(SERVER_DATE_FORMAT),
        end: moment(dateRange.endDate).format(SERVER_DATE_FORMAT),
      }
      const nextPeriodQuery = {
        start: moment(dateRange.startDate).add(periodDays, 'days').format(SERVER_DATE_FORMAT),
        end: moment(dateRange.endDate).add(periodDays, 'days').format(SERVER_DATE_FORMAT),
      }
      const { data: { statistics: previousPeriodInvoiceStatistics } } = await request.get('/statistics/invoices', { params: previousPeriodQuery })
      const { data: { statistics: nextPeriodInvoiceStatistics } } = await request.get('/statistics/invoices', { params: nextPeriodQuery })

      const { data: { statistics: invoiceStatistics } } = await request.get('/statistics/invoices', { params: periodQuery })

      const { data: { statistics: occupancyStatistics } } = await request.get('/statistics/occupancy', { params: periodQuery })

      setRevenue({
        previous: previousPeriodInvoiceStatistics.total || 0,
        current: invoiceStatistics.total || 0,
        next: nextPeriodInvoiceStatistics.total || 0,
      })

      setInvoiceStatistics(invoiceStatistics)

      setTotalRevenue(invoiceStatistics.total)
      setMonthRevenueSeries(monthlyToSeries(invoiceStatistics.month))
      setVenueRevenueSeries(venueToSeries(venues, invoiceStatistics.venue))
      setTotalOccupancy(occupancyStatistics.total / 365)
      setVenueOccupancySeries(venueOccupancyToSeries(venues, occupancyStatistics.venue))
    }

    if (!isEmpty(venues)) {
      fetch()
    }
  }, [ venues, dateRange.startDate, dateRange.endDate ])

  const charts = [
    {
      type: 'number',
      title: 'Chiffre d\'affaires (période précédente)',
      dimensions: [],
      serie: { total: formatCurrency(revenue.previous) },
      coordinates: { x: 0, y: 0, w: 4, h: 1 },
    },
    {
      type: 'number',
      title: 'Chiffre d\'affaires',
      dimensions: [],
      serie: { total: formatCurrency(revenue.current) },
      coordinates: { x: 4, y: 0, w: 4, h: 1 },
    },
    {
      type: 'number',
      title: 'Chiffre d\'affaires (période suivante)',
      dimensions: [],
      serie: { total: formatCurrency(revenue.next) },
      coordinates: { x: 8, y: 0, w: 4, h: 1 },
    },
  ]

  return (
    <>
      <div className="content">
        <div className="dashboard">
          <div className="dashboard__controls">
            <DateRangePicker dateRange={ dateRange } onChange={ handleChangeDateRange } />
          </div>
          <div className="dashboard__charts">
            { map((chart) => {
              const Component = chartTypeComponentMap[chart.type]
              return <Component { ...chart } key={ `${chart.type}-${chart.title}` } />
            }, charts) }
          </div>
        </div>
      </div>
      <Layout className="dashboard">

        <div className="uk-margin-medium-top" data-uk-grid>
          <div className="uk-width-1-2@m">
            <h4 className="uk-heading-line uk-margin-small-bottom"><span>Chiffre d’affaires par mois</span></h4>
            <div className="uk-height-medium">
              <ResponsiveLine
                data={ monthRevenueSeries }
                margin={{ top: 20, right: 20, bottom: 25, left: 25 }}
                xScale={ {
                  type: 'time',
                  format: '%Y-%m',
                  useUTC: false,
                  precision: 'day',
                } }
                xFormat="time:%Y-%m"
                yFormat={ (v) => formatCurrency(v) }
                axisBottom={ {
                  format: '%b',
                  tickValues: 'every month'
                } }
                curve="monotoneX"
                useMesh={ true }
              />
            </div>
          </div>
          <div className="uk-width-1-2@m uk-height-medium">
            <h4 className="uk-heading-line uk-margin-small-bottom"><span>Chiffre d’affaires par salle par mois</span></h4>
            <div className="uk-height-medium">
              <ResponsiveLine
                data={ venueRevenueSeries }
                margin={{ top: 20, right: 110, bottom: 25, left: 50 }}
                xScale={ {
                  type: 'time',
                  format: '%Y-%m',
                  useUTC: false,
                  precision: 'day',
                } }
                xFormat="time:%b %Y"
                yFormat={ (v) => formatCurrency(v) }
                axisBottom={ {
                  format: '%b',
                  tickValues: 'every month'
                } }
                curve="monotoneX"
                useMesh={ true }
                legends={ [
                  {
                    anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  }
                ] }
              />
            </div>
          </div>
          <div className="uk-width-1-2@m uk-margin-large-top">
            <h4 className="uk-heading-line uk-margin-small-bottom"><span>Taux d'occupation</span></h4>

            <h1>{ Math.floor(totalOccupancy * 100) }%</h1>
          </div>
          <div className="uk-width-1-2@m uk-height-medium uk-margin-large-top">
            <h4 className="uk-heading-line uk-margin-small-bottom"><span>Taux d'occupation par salle par mois</span></h4>
            <div className="uk-height-medium">
              <ResponsiveLine
                data={ venueOccupancySeries }
                margin={{ top: 20, right: 110, bottom: 35, left: 50 }}
                xScale={ {
                  type: 'time',
                  format: '%Y-%m',
                  useUTC: false,
                  precision: 'day',
                } }
                xFormat="time:%b %Y"
                yFormat={ v => `${v}%` }
                axisBottom={ {
                  format: '%b',
                  tickValues: 'every month'
                } }
                curve="monotoneX"
                useMesh={ true }
                legends={ [
                  {
                    anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  }
                ] }
              />
            </div>
          </div>
          <div className="uk-width-1-1@m uk-height-medium" style={{ display: 'none' }}>
            <h4 className="uk-heading-line uk-margin-small-bottom"><span>Réservations par jour</span></h4>

            <div className="uk-height-medium">
              <Calendar
                data={ fakeCalendarData }
                from={ calendarStart.format('YYYY-MM-DD') }
                to={ calendarStart.endOf('year').format('YYYY-MM-DD') }
                emptyColor="#eeeeee"
                colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DashboardScreen
