import React, { useState, useEffect } from 'react'
import { path, compose, defaultTo, update } from 'ramda'
import { pickDeepDefined } from '../../helpers'

const getTargetValue = path([ 'target', 'value' ])
const pathOr = (f, p, o) => defaultTo(f, path(p, o))

const TasksForm = (props) => {
  const { initialFormData, setFormData, onDelete, i } = props

  const [ title, setTitle ] = useState(pathOr('', [ 'title' ], initialFormData))
  const [ description, setDescription ] = useState(pathOr('', [ 'description' ], initialFormData))

  useEffect(() => {
    const payload = pickDeepDefined({ title, description, isCompleted: false })
    setFormData(update(i - 1, payload))
  }, [ title, description, i, setFormData ])

  const handleDelete = () => onDelete(i - 1)

  return (
    <form className="uk-form-horizontal">
      { !initialFormData && 
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <h4>Tâche #{ i }</h4>
          <span data-uk-icon="icon: close" className="uk-text-danger cp" onClick={ handleDelete }>Supprimer </span>
        </div>
      }

      <div className="uk-margin">
        <label className="uk-form-label">Tâche *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ title } onChange={ compose(setTitle, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Description *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ description } onChange={ compose(setDescription, getTargetValue) } />
        </div>
      </div>
    </form>
  )
}

export default TasksForm
