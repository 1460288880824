import React, { useState, useEffect } from 'react'
import { path, compose, defaultTo, join, keys, propOr } from 'ramda'

const getTargetValue = path([ 'target', 'value' ])
const pathOr = (f, p, o) => defaultTo(f, path(p, o))

const ProcedureForm = (props) => {
  const { categories, initialFormData, setFormData } = props

  const [ name, setName ] = useState(pathOr('', [ 'name' ], initialFormData))
  const [ category, setCategory ] = useState(pathOr('', [ 'categories', 0 ], initialFormData))
  const [ subCategory, setSubCategory ] = useState(pathOr('', [ 'categories', 1 ], initialFormData))
  const [ content, setContent ] = useState(pathOr('', [ 'content' ], initialFormData))

  useEffect(() => {
    setFormData({
      name,
      categories: [ category, subCategory ],
      content
    })
  }, [ name, category, subCategory, content, setFormData ])

  return (
    <form className="uk-form-horizontal">
    
      <div className="uk-margin">
        <label className="uk-form-label">Nom *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ name } onChange={ compose(setName, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Catégorie *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" placeholder={ join(', ', keys(categories)) } value={ category } onChange={ compose(setCategory, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Sous-catégorie *</label>
        <div className="uk-form-controls">
          <input type="email" className="uk-input" placeholder={ join(', ', propOr([], category, categories)) } value={ subCategory } onChange={ compose(setSubCategory, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Contenu *</label>
        <div className="uk-form-controls">
          <textarea className="uk-textarea" rows="4" value={ content } onChange={ compose(setContent, getTargetValue) } />
        </div>
      </div>
    </form>
  )
}

export default ProcedureForm