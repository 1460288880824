import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { join, reject, isNil, isEmpty } from 'ramda'

import { itemSelector, sliceSelector } from '../../users/state/users-selectors'
import { fetch, update } from '../../users/state/users-reducer'

import Layout from '../../components/layout'
import EmployeeForm from '../components/employee-form'

const EditEmployeeScreen = (props) => {
  const { match: { params: { employeeId } } } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { all: { isFetching, isCached, error: usersError } } = useSelector(sliceSelector)
  const employee = useSelector(itemSelector(employeeId))

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetch())
  }, [ dispatch, isCached, isFetching ])

  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ usersError, globalError ]))

  const [ formData, setFormData ] = useState({})

  const handleSubmit = () => {
    dispatch(update(employeeId, formData, () => history.push('/employees'), setGlobalError))
  }
  return (
    <Layout>
      <h3>Modifier l'employé</h3>

      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && !isFetching &&
        <EmployeeForm { ...{ initialFormData: employee, setFormData } } />
      }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" disabled={ isFetching } onClick={ handleSubmit }>Modifier</button>
      </div>
    </Layout>
  )
}

export default EditEmployeeScreen