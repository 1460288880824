import React from 'react'
import { compose, path } from 'ramda'

const getTargetValue = path([ 'target', 'value' ])

const TimeInput = (props) => {
  const { value, onChange } = props

  return (
    <div className="time-input">
      <input type="time" value={ value } onChange={ compose(onChange, getTargetValue) } />
    </div>
  )
}

export default TimeInput
