import React, { useState, useEffect } from 'react'
import { head, reduce, isEmpty, append, isNil, path, addIndex, compose, remove, map, update, defaultTo, sortBy, prop, toPairs } from 'ramda'
import moment from 'moment'

import { SERVER_DATETIME_FORMAT, DATE_FORMAT, SERVER_DATE_FORMAT } from '../../helpers'

import TasksForm from './tasks-form'
import DateInput from '../../components/date-input'
import MultiDateInput from '../../components/multi-date-input'
import TimeInput from '../../components/time-input'

const getTargetValue = path([ 'target', 'value' ])
const pathOr = (f, p, o) => defaultTo(f, path(p, o))
const mapIndexed = addIndex(map)

const templates = {
  reception1: { startTime: '17:30', endTime: '22:30' },
  reception2: { startTime: '08:00', endTime: '20:00' },
  administration1: { startTime: '09:00', endTime: '17:30' },
  administration2: { startTime: '07:30', endTime: '16:00' },
  maintenance1: { startTime: '06:30', endTime: '15:30' },
  maintenance2: { startTime: '06:30', endTime: '12:30' },
}

const SchedulesForm = (props) => {
  const { users, initialFormData, setFormData, onDelete, i } = props
  const isEditing = !isNil(initialFormData)

  const [ type, setType ] = useState(pathOr('work', [ 'type' ], initialFormData))

  const [ employee, setEmployee ] = useState(pathOr('', [ '_employee', '_id' ], initialFormData))

  const defaultPlannedStartMoment = moment()
  const initialPlannedStartMoment = moment(pathOr(defaultPlannedStartMoment, [ 'plannedStart' ], initialFormData))

  const initialDates = [ initialPlannedStartMoment.format(SERVER_DATE_FORMAT) ]
  const [ dates, setDates ] = useState(initialDates)
  
  const initialStartTime = initialPlannedStartMoment.format('HH:mm')
  const [ startTime, setStartTime ] = useState(initialStartTime)

  const defaultPlannedEndMoment = moment().add(8, 'hours')
  const initialPlannedEndMoment = moment(pathOr(defaultPlannedEndMoment, [ 'plannedEnd' ], initialFormData))

  const initialEndTime = initialPlannedEndMoment.format('HH:mm')
  const [ endTime, setEndTime ] = useState(initialEndTime)

  const handleDelete = () => onDelete(i - 1)

  const handleSelectTemplate = (templateId) => {
    if (isEmpty(templateId)) return

    const {
      startTime: newStartTime,
      endTime: newEndTime,
    } = templates[templateId]
    setStartTime(newStartTime)
    setEndTime(newEndTime)
  }

  const initialTaskForms = !isNil(pathOr(null, [ 'tasks' ], initialFormData))
    ? mapIndexed((task, idx) => (formProps) => <TasksForm { ...formProps } initialFormData={ task } key={ idx + 1 } i={ idx + 1 } />, initialFormData.tasks)
    : []
  const [ taskForms, setTaskForms ] = useState(initialTaskForms)
  const initialTaskFormData = !isNil(pathOr(null, [ 'tasks' ], initialFormData))
    ? initialFormData.tasks
    : []
  const [ taskFormData, setTaskFormData ] = useState(initialTaskFormData)

  const selectedTemplate = reduce((acc, [ key, t ]) => {
    const isSelectedTemplate = t.startTime === startTime && t.endTime === endTime
    return isSelectedTemplate ? key : acc
  }, '', toPairs(templates))

  const handleAddTaskForm = (e) => {
    e.preventDefault()
    setTaskForms((oldList) => append((formProps) => <TasksForm { ...formProps } key={ oldList.length + 1 } i={ oldList.length + 1 } />, oldList))
    setTaskFormData(append({}))
  }

  const handleDeleteTaskForm = (i) => {
    setTaskForms(remove(i, 1))
    setTaskFormData(remove(i, 1))
  }

  useEffect(() => {
    const payload = {
      type,
      employeeId: employee,
      dates,
      startTime,
      endTime,
      tasks: taskFormData,
    }

    setFormData(update(i - 1, payload))
  }, [ startTime, endTime, taskFormData, type, dates, employee, i, setFormData ])

  return (
    <>
    <form className="uk-form-horizontal">
      { !initialFormData && 
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <h4>Créneau #{ i }</h4>
          <span data-uk-icon="icon: close" className="uk-text-danger cp" onClick={ handleDelete }>Supprimer </span>
        </div>
      }

      <div className="uk-margin">
        <label className="uk-form-label">Type de créneau *</label>
          <div className="uk-form-controls uk-flex">
            <select className="uk-select" value={ type } onChange={ compose(setType, getTargetValue) }>
              <option value="work">Travail</option>
              <option value="holiday">Vacances</option>
            </select>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Employé(e) *</label>
          <div className="uk-form-controls uk-flex">
            <select className="uk-select" value={ employee } onChange={ compose(setEmployee, getTargetValue) }>
              <option disabled value="" />
              { map((user) => <option value={ user._id } key={ user._id }>{ user.displayName }</option>, sortBy(prop('displayName'), users)) }
            </select>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Date(s) *</label>
        <div className="uk-form-controls">
          { isEditing &&
            <DateInput
              value={ dates[0] }
              onChange={ (d) => setDates([ d ]) }
            />
          }
          { !isEditing &&
            <MultiDateInput
              value={ dates }
              onChange={ setDates }
            />
          }
        </div>
      </div>

      { type !== 'holiday' &&
        <>
          <div className="uk-margin">
            <label className="uk-form-label">Créneau standard</label>
              <div className="uk-form-controls uk-flex">
                <select className="uk-select" value={ selectedTemplate } onChange={ compose(handleSelectTemplate, getTargetValue) }>
                  <option value=""></option>
                  <option value="reception1">Réception (17:30 - 22:30)</option>
                  <option value="reception2">Réception (08:00 - 20:00)</option>
                  <option value="administration1">Administration (09:00 - 17:30)</option>
                  <option value="administration2">Administration (07:30 - 16:00)</option>
                  <option value="maintenance1">Housekeeping (06:30 - 15:30)</option>
                  <option value="maintenance2">Housekeeping (06:30 - 12:30)</option>
                </select>
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label">Horaires *</label>
            <div className="uk-form-controls uk-grid-small" data-uk-grid>
              <div className="uk-width-1-2 uk-padding-remove-left uk-padding-right">
                <TimeInput
                  value={ startTime }
                  onChange={ setStartTime }
                />
              </div>
              <div className="uk-width-1-2 uk-padding-left">
                <TimeInput
                  value={ endTime }
                  onChange={ setEndTime }
                />
              </div>
            </div>
          </div>
        </>
      }

      <div className="uk-margin">
        { map((fn) => fn({ setFormData: setTaskFormData, onDelete: handleDeleteTaskForm }), taskForms) }

        <button className="uk-button uk-button-link" onClick={ handleAddTaskForm }>Ajouter une tâche</button>
      </div>
    </form>
    </>
  )
}

export default SchedulesForm
