import { createSlice } from '@reduxjs/toolkit'
import { pluck, merge, uniq, concat, assocPath, compose, append, assoc } from 'ramda'
import { mapIdToObj } from '../../helpers.js'

import request from '../../request'

const initialState = {
  byId: {},
  all: {
    ids: [],
    isCached: false,
    isFetching: false,
    error: null,
  }
}

const slice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    fetching: (state, action) => {
      return assocPath([ 'all', 'isFetching' ], true, state)
    },
    notFetched: (state, action) => {
      return compose(
        assocPath([ 'all', 'error' ], action.payload),
        assocPath([ 'all', 'isFetching' ], false)
      )(state)
    },
    fetched: (state, action) => {
      const byId = mapIdToObj(action.payload)
      const ids = pluck('_id', action.payload)
      
      return {
        byId: merge(state.byId, byId),
        all: { ids: uniq(concat(state.all.ids, ids)), isCached: true, isFetching: false }
      }
    },
    fetchedOne: (state, action) => {
      const byId = assoc(action.payload._id, action.payload, state.byId)
      const ids = uniq(append(action.payload._id, state.all.ids))

      return { byId, all: assoc('ids', ids, state.all) }
    },
    hadError: (state, action) => assocPath([ 'all', 'error' ], action.payload, state)
  },
})

const { fetching, fetched, notFetched } = slice.actions

export const fetch = () => async (dispatch) => {
  try {
    dispatch(fetching())
    const { data: { venues } } = await request.get('/venues')
    dispatch(fetched(venues))
  } catch (err) {
    dispatch(notFetched(err.toString()))
  }
}

export default slice.reducer