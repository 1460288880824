import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { compose, path } from 'ramda'

import { login } from '../state/auth-reducer'

const getTargetValue = path([ 'target', 'value' ])

const LoginScreen = () => {
const dispatch = useDispatch()
const history = useHistory()

const [ email, setEmail ] = useState('')
const [ password, setPassword ] = useState('')

const [ isLoading, setIsLoading ] = useState(false)
const [ error, setError ] = useState()

const handleSubmit = (e) => {
  e.preventDefault()
  setIsLoading(true)

  const onError = ({ message }) => {
    setError(message)
    setIsLoading(false)
  }
  const onSuccess = () => {
    history.push('/dashboard')
  }

  dispatch(login(
    { email, password },
    onError,
    onSuccess
  ))
}
return (
  <div className="uk-section uk-flex uk-flex-middle" data-uk-height-viewport>
    <div className="uk-width-1-1">
      <div className="uk-container">
        <div className="uk-grid uk-grid-stack uk-grid-margin" data-uk-grid>
          <div className="uk-width-1-1@m">
            <div className="uk-card uk-card-default uk-card-body uk-box-shadow-large uk-margin uk-width-large uk-margin-auto">
              { error &&
                <div className="uk-alert-danger" data-uk-alert>
                  <p>{ error }</p>
                </div>
              }
              <form onSubmit={ handleSubmit }>
                <div className="uk-margin">
                  <div className="uk-inline uk-width-1-1">
                    <span className="uk-form-icon" data-uk-icon="icon: mail" />
                    <input className="uk-input uk-form-large" type="email" value={ email } onChange={ compose(setEmail, getTargetValue) } />
                  </div>
                </div>

                <div className="uk-margin">
                  <div className="uk-inline uk-width-1-1">
                    <span className="uk-form-icon" data-uk-icon="icon: lock" />
                    <input className="uk-input uk-form-large" type="password" value={ password } onChange={ compose(setPassword, getTargetValue) } />
                  </div>
                </div>

                <div className="uk-margin">
                  <button className="uk-button uk-button-primary uk-button-large uk-width-1-1">
                    { !isLoading && <>Connexion</> }
                    { isLoading && <div data-uk-spinner /> }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
}

export default LoginScreen