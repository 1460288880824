import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { all, identity, join, reject, isNil, isEmpty } from 'ramda'

import { sliceSelector as clientsSliceSelector, allSelector as clientsAllSelector } from '../../clients/state/clients-selectors'
import { fetch as fetchClients } from '../../clients/state/clients-reducer'
import { sliceSelector as venuesSliceSelector, allSelector as venuesAllSelector } from '../../venues/state/venues-selectors'
import { fetch as fetchVenues } from '../../venues/state/venues-reducer'
import { sliceSelector, itemSelector } from '../state/bookings-selectors'
import { fetch, update } from '../state/bookings-reducer'

import { pickDeepDefined } from '../../helpers'

import Layout from '../../components/layout'
import BookingsForm from '../components/bookings-form'

const CreateBookingScreen = (props) => {
  const { match: { params: { bookingId } } } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { all: { isFetching: isClientsFetching, isCached: isClientsCached, error: clientsError } } = useSelector(clientsSliceSelector)
  const { all: { isFetching: isVenuesFetching, isCached: isVenuesCached, error: venuesError } } = useSelector(venuesSliceSelector)
  const { all: { isFetching: isBookingsFetching, isCached: isBookingsCached, error: bookingsError } } = useSelector(sliceSelector)
  const clients = useSelector(clientsAllSelector)
  const venues = useSelector(venuesAllSelector)
  const booking = useSelector(itemSelector(bookingId))

  useEffect(() => {
    if (!isClientsCached && !isClientsFetching) dispatch(fetchClients())
    if (!isVenuesCached && !isVenuesFetching) dispatch(fetchVenues())
    if (!isBookingsCached && !isBookingsFetching) dispatch(fetch())
  }, [ dispatch, isClientsCached, isClientsFetching, isVenuesCached, isVenuesFetching, isBookingsCached, isBookingsFetching ])

  const isFetching = all(identity, [ isClientsFetching, isVenuesFetching, isBookingsFetching ])
  const isCached = all(identity, [ isClientsCached, isVenuesCached, isBookingsCached ])
  const [ globalError, setGlobalError ] = useState()
  const error = join(', ', reject(isNil, [ clientsError, venuesError, bookingsError, globalError ]))

  const [ formData, setFormData ] = useState([ {} ])

  const handleSubmit = () => {
    dispatch(update(bookingId, pickDeepDefined(formData[0]), () => history.push('/bookings', { viewType: 'calendar' }), setGlobalError))
  }
  return (
    <Layout>
      <h3>Modifier la réservation</h3>


      { isFetching && <div data-uk-spinner></div> }

      { !isEmpty(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached && !isFetching &&
        <BookingsForm { ...{ clients, venues, initialFormData: booking, i: 1, setFormData } } />
      }

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" disabled={ isFetching } onClick={ handleSubmit }>Modifier</button>
      </div>
    </Layout>
  )
}

export default CreateBookingScreen