import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { isNil, map } from 'ramda'

import Layout from '../../components/layout'

import { sliceSelector, allSelector } from '../state/procedures-selectors'
import { fetch } from '../state/procedures-reducer'
import { getCategories, getSubCategoriesForCategory, getProceduresForCategories } from '../state/procedures-helpers'

const ProceduresScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const allProcedures = useSelector(allSelector)

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetch()) 
  }, [ dispatch, isCached, isFetching ])

  const categories = getCategories(allProcedures)

  const [ selectedCategory, setSelectedCategory ] = useState()
  const [ selectedSubCategory, setSelectedSubCategory ] = useState()

  const subCategories = getSubCategoriesForCategory(selectedCategory, allProcedures)
  const procedures = getProceduresForCategories(selectedCategory, selectedSubCategory, allProcedures)

  const handleSelectCategory = (category) => () => setSelectedCategory(category)
  const handleSelectSubCategory = (subCategory) => () => setSelectedSubCategory(subCategory)
  const handleClickProcedure = (procedureId) => () => history.push(`/procedures/${procedureId}`)
  const handleClickCreate = () => history.push('/procedures/new')

  return (
    <Layout>
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
        <h3 className="uk-margin-remove-bottom">Procédures</h3>
        <button className="uk-button uk-button-primary" onClick={ handleClickCreate }>Créer</button>
      </div>

      { isFetching && <div data-uk-spinner></div> }
      { !isNil(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }
      { isCached &&
        <div className="uk-margin-medium-top" data-uk-grid>
        <div className="uk-width-1-3">
          <h5 className="uk-heading-divider uk-margin-small-bottom">Catégorie</h5>

          <ul className="uk-list uk-list-divider">
            { map((category) => (
              <li key={ category } className="cp" onClick={ handleSelectCategory(category) }>{ category }</li>
            ), categories) }
          </ul>
        </div>
        <div className="uk-width-1-3">
          <h5 className="uk-heading-divider uk-margin-small-bottom">Sous-catégorie</h5>

          <ul className="uk-list uk-list-divider">
            { map((subCategory) => (
              <li key={ subCategory } className="cp" onClick={ handleSelectSubCategory(subCategory) }>{ subCategory }</li>
            ), subCategories) }
          </ul>
        </div>
        <div className="uk-width-1-3">
          <h5 className="uk-heading-divider uk-margin-small-bottom">Procédures</h5>
          
          <ul className="uk-list uk-list-divider">
            { map((procedure) => (
              <li key={ procedure._id } className="cp" onClick={ handleClickProcedure(procedure._id) }>{ procedure.name }</li>
            ), procedures) }
          </ul>
        </div>
      </div>
      }
    </Layout>
  )
}

export default ProceduresScreen