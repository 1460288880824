import { createSlice } from '@reduxjs/toolkit'
import { forEach, omit, reduce, has, path, identity, pluck, merge, uniq, concat, assocPath, compose, append, assoc, dissoc, reject, equals } from 'ramda'
import moment from 'moment'
import { Promise } from 'bluebird'

import { fetch as fetchClients } from '../../clients/state/clients-reducer'
import { fetch as fetchVenues } from '../../venues/state/venues-reducer'

import { denormalizeEntities, denormalizeEntity, mapIdToObj, SERVER_DATETIME_FORMAT } from '../../helpers.js'
import request from '../../request'

const initialState = {
  byId: {},
  all: {
    ids: [],
    isCached: false,
    isFetching: false,
    error: null,
  }
}

const slice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    fetching: (state, action) => {
      return assocPath([ 'all', 'isFetching' ], true, state)
    },
    notFetched: (state, action) => {
      return compose(
        assocPath([ 'all', 'error' ], action.payload),
        assocPath([ 'all', 'isFetching' ], false)
      )(state)
    },
    fetched: (state, action) => {
      const byId = mapIdToObj(action.payload)
      const ids = pluck('_id', action.payload)
      
      return {
        byId: merge(state.byId, byId),
        all: { ids: uniq(concat(state.all.ids, ids)), isCached: true, isFetching: false }
      }
    },
    fetchedOne: (state, action) => {
      const byId = assoc(action.payload._id, action.payload, state.byId)
      const ids = uniq(append(action.payload._id, state.all.ids))

      return { byId, all: assoc('ids', ids, state.all) }
    },
    deletedOne: (state, action) => {
      const byId = dissoc(action.payload, state.byId)
      const ids = reject(equals(action.payload), state.all.ids)

      return { byId, all: assoc('ids', ids, state.all) }
    },
    hadError: (state, action) => assocPath([ 'all', 'error' ], action.payload, state)
  },
})

const { fetching, fetched, notFetched, fetchedOne, deletedOne } = slice.actions

export const fetch = () => async (dispatch, getState) => {
  try {
    dispatch(fetching())

    const initialState = getState()
    const { venues: { all: { isCached: isVenuesCached } }, clients: { all: { isCached: isClientsCached } } } = initialState
    if (!isVenuesCached) {
      await fetchVenues()(dispatch, getState)
    }
    if (!isClientsCached) {
      await fetchClients()(dispatch, getState)
    }

    const query = { start: '2020-01-01', end: '2025-12-31' }
    const { data: { bookings } } = await request.get('/bookings', { params: query })
    
    const state = getState()
    const denormalized = denormalizeEntities(state, bookings)

    dispatch(fetched(denormalized))
  } catch (err) {
    dispatch(notFetched(err.toString()))
  }
}

const splitBookingDates = (bookings) => reduce((acc, booking) => {
  if (has('dates', booking)) {
    const { startTime, endTime, dates } = booking
    const template = omit([ 'startTime', 'endTime', 'dates' ], booking)

    forEach((date) => {
      const start = moment(`${date}T${startTime}`, 'YYYY-MM-DD[T]HH:mm').format(SERVER_DATETIME_FORMAT)
      const end = moment(`${date}T${endTime}`, 'YYYY-MM-DD[T]HH:mm').format(SERVER_DATETIME_FORMAT)

      const item = merge(template, { start, end })

      acc.push(item)
    }, dates)
  } else {
    acc.push(booking)
  }

  return acc
}, [], bookings)

export const bulkCreate = (partialBookings, onSuccess, onError) => async (dispatch, getState) => {
  try {
    const splitBookings = splitBookingDates(partialBookings)
    const bookings = await Promise.map(splitBookings, (partialBooking) => {
      return request.post('/bookings', partialBooking)
        .then(path([ 'data', 'booking' ]))
    })

    const initialState = getState()
    const { venues: { all: { isCached: isVenuesCached } }, clients: { all: { isCached: isClientsCached } } } = initialState
    if (!isVenuesCached) {
      await fetchVenues()(dispatch, getState)
    }
    if (!isClientsCached) {
      await fetchClients()(dispatch, getState)
    }

    const state = getState()
    const denormalized = denormalizeEntities(state, bookings)

    dispatch(fetched(denormalized))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const update = (bookingId, partialBooking, onSuccess, onError) => async (dispatch, getState) => {
  try {
    const { data: { booking } } = await request.put(`/bookings/${bookingId}`, partialBooking)

    const initialState = getState()
    const { venues: { all: { isCached: isVenuesCached } }, clients: { all: { isCached: isClientsCached } } } = initialState
    if (!isVenuesCached) {
      await fetchVenues()(dispatch, getState)
    }
    if (!isClientsCached) {
      await fetchClients()(dispatch, getState)
    }

    const state = getState()
    const denormalized = denormalizeEntity(state, booking)

    dispatch(fetchedOne(denormalized))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const del = (bookingId, onSuccess, onError) => async (dispatch) => {
  try {
    await request.delete(`/bookings/${bookingId}`)

    dispatch(deletedOne(bookingId))
    onSuccess()
  } catch (err) {
    onError(err.toString())
  }
}

export const markInvoiceSent = (bookingId) => async (dispatch, getState) => {
  const booking = getState().bookings.byId[bookingId]
  const { invoice } = booking

  const payload = assoc('isSent', true, invoice)

  return update(bookingId, { invoice: payload }, identity, identity)(dispatch, getState)
}

export const updateInvoiceSentStatus = (bookingId, isSent) => async (dispatch, getState) => {
  const booking = getState().bookings.byId[bookingId]
  const { invoice } = booking

  const payload = assoc('isSent', isSent, invoice)

  return update(bookingId, { invoice: payload }, identity, identity)(dispatch, getState)
}

export const updateInvoicePaidStatus = (bookingId, isPaid) => async (dispatch, getState) => {
  const booking = getState().bookings.byId[bookingId]
  const { invoice } = booking

  const payload = assoc('isPaid', isPaid, invoice)

  return update(bookingId, { invoice: payload }, identity, identity)(dispatch, getState)
}

export const markInvoicePaid = (bookingId) => async (dispatch, getState) => {
  const booking = getState().bookings.byId[bookingId]
  const { invoice } = booking

  const payload = assoc('isPaid', true, invoice)

  return update(bookingId, { invoice: payload }, identity, identity)(dispatch, getState)
}

export default slice.reducer
