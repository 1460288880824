import React, { useState, useEffect } from 'react'
import { map, path, compose, defaultTo } from 'ramda'

const getTargetValue = path([ 'target', 'value' ])
const pathOr = (f, p, o) => defaultTo(f, path(p, o))

const types = [ 'Entreprise', 'Association', 'Fondation', 'Particulier' ]

const ClientForm = (props) => {
  const { initialFormData, setFormData } = props

  const [ name, setName ] = useState(pathOr('', [ 'name' ], initialFormData))
  const [ abbreviation, setAbbreviation ] = useState(pathOr('', [ 'abbreviation' ], initialFormData))
  const [ email, setEmail ] = useState(pathOr('', [ 'email' ], initialFormData))
  const [ phone, setPhone ] = useState(pathOr('', [ 'phone' ], initialFormData))
  const [ company, setCompany ] = useState(pathOr('', [ 'company' ], initialFormData))
  const [ type, setType ] = useState(pathOr('', [ 'type' ], initialFormData))
  const [ addressLine, setAddressLine ] = useState(pathOr('', [ 'address', 'line' ], initialFormData))
  const [ addressZip, setAddressZip ] = useState(pathOr('', [ 'address', 'zip' ], initialFormData))
  const [ addressCity, setAddressCity ] = useState(pathOr('', [ 'address', 'city' ], initialFormData))
  const [ addressCountry, setAddressCountry ] = useState(pathOr('', [ 'address', 'country' ], initialFormData))
  const [ notes, setNotes ] = useState(pathOr('', [ 'notes' ], initialFormData))
  
  useEffect(() => {
    setFormData({
      name,
      abbreviation,
      email,
      phone,
      company,
      type,
      notes,
      address: { line: addressLine, zip: addressZip, city: addressCity, country: addressCountry }
    })
  }, [ name, notes, abbreviation, email, type, phone, company, addressLine, addressZip, addressCity, addressCountry, setFormData ])

  return (
    <form className="uk-form-horizontal">
      <div className="uk-margin">
        <label className="uk-form-label">Entreprise *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ company } onChange={ compose(setCompany, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Type d'entreprise *</label>
        <div className="uk-form-controls">
          <select className="uk-select" value={ type } onChange={ compose(setType, getTargetValue) }>
            <option disabled value="" />
            { map((type) => <option value={ type } key={ type }>{ type }</option>, types) }
          </select>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Nom *</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ name } onChange={ compose(setName, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Abréviation</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" value={ abbreviation } onChange={ compose(setAbbreviation, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Email</label>
        <div className="uk-form-controls">
          <input type="email" className="uk-input" value={ email } onChange={ compose(setEmail, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Numéro de téléphone</label>
        <div className="uk-form-controls">
          <input type="text" className="uk-input" placeholder="+41 012 345 67 89" value={ phone } onChange={ compose(setPhone, getTargetValue) } />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Adresse</label>
        <div className="uk-form-controls uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1 uk-padding-remove-left">
            <input type="text" className="uk-input" placeholder="Adresse" value={ addressLine } onChange={ compose(setAddressLine, getTargetValue) } />
          </div>
          <div className="uk-width-1-2 uk-padding-remove-left">
            <input type="text" className="uk-input" placeholder="Code postal" value={ addressZip } onChange={ compose(setAddressZip, getTargetValue) } />
          </div>
          <div className="uk-width-1-2">
            <input type="text" className="uk-input" placeholder="Ville" value={ addressCity } onChange={ compose(setAddressCity, getTargetValue) } />
          </div>
          <div className="uk-width-1-1 uk-padding-remove-left">
            <input type="text" className="uk-input" placeholder="Pays" value={ addressCountry } onChange={ compose(setAddressCountry, getTargetValue) } />
          </div>
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Information supplémentaire</label>
        <div className="uk-form-controls">
          <textarea className="uk-textarea" rows="3" value={ notes } onChange={ compose(setNotes, getTargetValue) } />
        </div>
      </div>
    </form>
  )
}

export default ClientForm
