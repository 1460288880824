import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { DATE_FORMAT, DATETIME_FORMAT } from '../../helpers'
import DataTable from '../../components/data-table'
import {defaultTo} from 'ramda'

const Row = ({ onClickSchedule }) => ({ item: schedule }) => {
  const { plannedStart, plannedEnd, actualStart, actualEnd, _employee } = schedule

  const countedStart = defaultTo(plannedStart, actualStart)
  const countedEnd = defaultTo(plannedEnd, actualEnd)

  const plannedHours = Math.round(moment(plannedEnd).diff(plannedStart, 'seconds') / 60 / 60 * 10) / 10
  const actualHours = Math.round(moment(countedEnd).diff(countedStart, 'seconds') / 60 / 60 * 10) / 10

  return (
    <tr onClick={ onClickSchedule(schedule._id) }>
      <td></td>
      <td>
        <div>{ moment(plannedStart).format(DATE_FORMAT) }</div>
        <div>Planifié de { moment(plannedStart).format('HH:mm') } à { moment(plannedEnd).format('HH:mm') }</div>
        { actualStart &&
          <div>Réalisé de { moment(countedStart).format('HH:mm') } à { moment(countedEnd).format('HH:mm') }</div>
        }
      </td>
      <td>{ plannedHours }h</td>
      <td>{ actualHours }h</td>
      <td>{ Math.round((actualHours - plannedHours) * 10) / 10}h</td>
      <td>{ _employee.displayName }</td>
    </tr>
  )
}

const SchedulesTable = ({ schedules }) => {
  const history = useHistory()

  const headings = [
    { label: 'Planifié', type: 'date', prop: [ 'plannedStart' ] },
    { label: 'Heures planifiées', type: 'number', prop: [ 'plannedStart' ] },
    { label: 'Heures réalisées', type: 'number', prop: [ 'actualStart' ] },
    { label: 'Solde heures', type: 'number', prop: [ 'actualStart' ] },
    { label: 'Employé', prop: [ '_employee', 'displayName' ] },
  ]

  const onClickSchedule = (scheduleId) => () =>
    history.push(`/schedules/${scheduleId}`)

  return (
    <DataTable
      list={ schedules }
      headings={ headings }
      rowRenderer={ Row({ onClickSchedule }) }
      initialSortIndex={ 0 }
    />
  )
}

export default SchedulesTable
