import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isNil } from 'ramda'

import Layout from '../../components/layout'
import ClientsTable from '../components/clients-table'

import { sliceSelector, allSelector } from '../state/clients-selectors'
import { fetch } from '../state/clients-reducer'

const ClientsScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const clients = useSelector(allSelector)

  useEffect(() => {
    if (!isCached) dispatch(fetch()) 
  }, [ dispatch, isCached ])

  const handleClickCreate = () => {
    history.push('/clients/new')
  }

  return (
    <Layout>
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
        <h3 className="uk-margin-remove-bottom">Clients</h3>
        <button className="uk-button uk-button-primary" onClick={ handleClickCreate }>Créer</button>
      </div>

      { isFetching && <div data-uk-spinner></div> }
      { !isNil(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      { isCached &&
        <ClientsTable clients={ clients } />
      }
    </Layout>
  )
}

export default ClientsScreen