import React from 'react'
import { useHistory } from 'react-router-dom'

import DataTable from '../../components/data-table'

const Row = ({ handleClickClient }) => ({ item: client }) => {
  const { name, email, company } = client
  return (
    <tr onClick={ handleClickClient(client._id) }>
      <td></td>
      <td>{ company }</td>
      <td>{ name }</td>
      <td>{ email }</td>
    </tr>
  )
}

const ClientsTable = ({ clients }) => {
  const history = useHistory()

  const headings = [
    { label: 'Entreprise', prop: [ 'company' ], searchable: true },
    { label: 'Nom', prop: [ 'name' ], searchable: true },
    { label: 'Email', prop: [ 'email' ], searchable: true },
  ]

  const handleClickClient = (clientId) => () => {
    history.push(`/clients/${clientId}`)
  }

  return (
    <DataTable
      list={ clients }
      headings={ headings }
      rowRenderer={ Row({ handleClickClient }) }
      initialSortIndex={ 0 }
      initialSortOrder="asc"
      searchable={ true }
    />
  )
}

export default ClientsTable
