import React from 'react'
import { useHistory } from 'react-router-dom'
import { map } from 'ramda'
import moment from 'moment'

import Calendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'

import { formatBookingForCalendar } from '../state/bookings-helpers'
import { SERVER_DATE_FORMAT } from '../../helpers'

const plugins = [ timeGridPlugin, dayGridPlugin ]
// const initialView = 'timeGridWeek'
const initialView = 'dayGridMonth'

const headerSetup = {
  left: 'prev,next',
  center: 'title',
  right: 'timeGridDay,timeGridWeek,dayGridMonth'
}

const height = 750

const MonthRenderer = ({ event }) => {
  const { time, _client, color } = event

  return (
    <>
      <div className="month-calendar__pill" style={{ backgroundColor: color }}></div>
      <span className="month-calendar__title">{ _client.company }</span>
      <span className="month-calendar__time">{ time }</span>
    </>
  )
}

const eventRenderer = (eventInfo) => {
  const { type: viewType } = eventInfo.view
  const { timeText, backgroundColor, event } = eventInfo
  const { extendedProps } = event
  const { isConfirmed } = extendedProps

  const e = { ...extendedProps, time: timeText, color: backgroundColor }

  if (viewType === 'dayGridMonth') return <MonthRenderer event={ e } />

  return (
    <>
      <div className="uk-margin-small-right">{eventInfo.timeText}</div>
      <div><strong>{eventInfo.event.title}</strong> ({isConfirmed ? 'Confirmée' : 'Option'})</div>
    </>
  )
}

const BookingsCalendar = ({ bookings }) => {
  const history = useHistory()

  const handleClickEvent = (eventInfo) => {
    const { _id: bookingId } = eventInfo.event.extendedProps
    history.push(`/bookings/${bookingId}`)
  }

  return (
    <Calendar { ...{
      plugins,
      initialView,
      headerToolbar: headerSetup,
      allDaySlot: false,
      nowIndicator: true,
      events: map(formatBookingForCalendar, bookings),
      height,
      locale: frLocale,
      eventContent: eventRenderer,
      eventClick: handleClickEvent,
    } }
    />
  )
}

export default BookingsCalendar
