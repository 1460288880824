import { sortBy, prop, map } from 'ramda'

export const getPositionStyles = (chart) => {
  const { coordinates } = chart
  const { x, y, w, h } = coordinates

  return {
    gridColumn: `${x + 1} / span ${w}`,
    gridRow: `${y + 1} / span ${h}`,
  }
}

export const serieToChartData = (serie) => {
  const { name, groups = [] } = serie
  return [
    {
      id: name,
      data: sortBy(prop('x'), map((group) => ({ x: group.key, y: group.total }), groups))
    }
  ]
}
