import { combineReducers } from '@reduxjs/toolkit'

import authReducer from '../auth/state/auth-reducer'
import bookingsReducer from '../bookings/state/bookings-reducer'
import alertsReducer from '../alerts/state/alerts-reducer'
import usersReducer from '../users/state/users-reducer'
import clientsReducer from '../clients/state/clients-reducer'
import venuesReducer from '../venues/state/venues-reducer'
import proceduresReducer from '../procedures/state/procedures-reducer'
import schedulesReducer from '../schedules/state/schedules-reducer'

export default combineReducers({
  auth: authReducer,
  bookings: bookingsReducer,
  alerts: alertsReducer,
  users: usersReducer,
  clients: clientsReducer,
  venues: venuesReducer,
  procedures: proceduresReducer,
  schedules: schedulesReducer,
})