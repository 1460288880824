import moment from 'moment'
import { propOr } from 'ramda'

const CALENDAR_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'

const lightenColor = (color, amount) => {
  if (!color) return '#000000'
  const colorCode = color.slice(1)
  const num = parseInt(colorCode, 16)
  
  let r = (num >> 16) + amount

  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }

  let b = ((num >> 8) & 0x00FF) + amount

  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }

  let g = (num & 0x0000FF) + amount

  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`
}

export const formatBookingForCalendar = (booking) => {
  const { start, end, isConfirmed } = booking

  const client = propOr({ company: 'Unknown client' }, '_client', booking)
  const venue = propOr({ color: '#000000' }, '_venue', booking)

  return {
    start: moment(start).format(CALENDAR_DATETIME_FORMAT),
    end: moment(end).format(CALENDAR_DATETIME_FORMAT),
    title: client.company,
    extendedProps: booking,
    color: isConfirmed ? venue.color : lightenColor(venue.color, 80),
  }
}
