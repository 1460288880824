import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { defaultTo, isNil } from 'ramda'
import moment from 'moment'

import Layout from '../../components/layout'

import { DATETIME_FORMAT } from '../../helpers'
import { sliceSelector, itemSelector } from '../state/alerts-selectors'
import { fetch } from '../state/alerts-reducer'
import { typeLabelMap } from '../state/alerts-helpers'

const AlertDetailsScreen = (props) => {
  const { match: { params: { alertId } } } = props
  const dispatch = useDispatch()

  const { all: { isFetching, isCached, error } } = useSelector(sliceSelector)
  const alert = useSelector(itemSelector(alertId))

  useEffect(() => {
    if (!isCached && !isFetching) dispatch(fetch())
  }, [ dispatch, isCached, isFetching ])

  const hasError = !isNil(error) || !alert

  return (
    <Layout>
      { isFetching && <div data-uk-spinner></div> }
      { !isFetching && hasError &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ defaultTo(`Alert with id ${alertId} does not exist.`, error) }</p>
        </div>
      }

      { isCached && !hasError &&
        <>
          <h3>Signalement ({ alert.isProcessed ? 'Résolu' : 'Non résolu' })</h3>

          <table className="uk-table uk-table-divider uk-margin-top">
            <tbody>
              <tr>
                <td className="uk-text-bold">Type</td>
                <td className="uk-text-left">{ typeLabelMap[alert.type] }</td>
              </tr>
              { alert.type === 'problem' &&
                <>
                  <tr>
                    <td className="uk-text-bold">Date du problème</td>
                    <td className="uk-text-left">{ moment(alert.content.date).format(DATETIME_FORMAT) }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Lieu</td>
                    <td className="uk-text-left">{ alert.content.location }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Description</td>
                    <td className="uk-text-left">{ alert.content.description }</td>
                  </tr>
                </>
              }
              { alert.type === 'loan' &&
                <>
                  <tr>
                    <td className="uk-text-bold">Date du prêt</td>
                    <td className="uk-text-left">{ moment(alert.content.date).format(DATETIME_FORMAT) }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Objet prêté</td>
                    <td className="uk-text-left">{ alert.content.item }</td>
                  </tr>
                  { alert.content.item === 'Autre' &&
                    <tr>
                      <td className="uk-text-bold">Description de l'objet prêté</td>
                      <td className="uk-text-left">{ alert.content.itemDescription }</td>
                    </tr>
                  }
                  <tr>
                    <td className="uk-text-bold">Prêté à</td>
                    <td className="uk-text-left">{ alert.content.to.firstName } { alert.content.to.lastName } ({ alert.content.to.type }, { alert.content.to.type === 'Client' ? `salle ${alert.content.to.venue}` : `chambre ${alert.content.to.room}` })</td>
                  </tr>
                </>
              }
              { alert.type === 'booking' &&
                <>
                  <tr>
                    <td className="uk-text-bold">Nom du client</td>
                    <td className="uk-text-left">{ alert.content.customer.name }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Entreprise</td>
                    <td className="uk-text-left">{ alert.content.customer.company }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Salle</td>
                    <td className="uk-text-left">{ alert.content.venue }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Dates souhaitées</td>
                    <td className="uk-text-left">{ moment(alert.content.start).format(DATETIME_FORMAT) } au { moment(alert.content.end).format(DATETIME_FORMAT) }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Email</td>
                    <td className="uk-text-left">{ alert.content.customer.email }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Numéro de téléphone</td>
                    <td className="uk-text-left">{ alert.content.customer.phone }</td>
                  </tr>
                </>
              }
              { alert.type === 'other' &&
                <>
                  <tr>
                    <td className="uk-text-bold">Date du divers</td>
                    <td className="uk-text-left">{ moment(alert.content.date).format(DATETIME_FORMAT) }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Lieu</td>
                    <td className="uk-text-left">{ alert.content.location }</td>
                  </tr>
                  <tr>
                    <td className="uk-text-bold">Description</td>
                    <td className="uk-text-left">{ alert.content.description }</td>
                  </tr>
                </>
              }
              <tr>
                <td className="uk-text-bold">Signalé par</td>
                <td className="uk-text-left">{ alert._createdBy.firstName } { alert._createdBy.lastName }</td>
              </tr>
              <tr>
                <td className="uk-text-bold">Signalé le</td>
                <td className="uk-text-left">{ moment(alert.createdAt).format(DATETIME_FORMAT) }</td>
              </tr>
            </tbody>
          </table>
        </>
      }
    </Layout>  
  )
}

export default AlertDetailsScreen
