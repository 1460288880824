import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNil } from 'ramda'

import { pickDeepDefined } from '../../helpers'

import Layout from '../../components/layout'
import ClientForm from '../components/employee-form'

import { create } from '../../users/state/users-reducer'

const CreateEmployeeScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [ formData, setFormData ] = useState({})
  const [ error, setError ] = useState()

  const handleSubmit = () => {
    dispatch(create(pickDeepDefined(formData), () => history.push('/employees'), setError))
  }

  return (
    <Layout>
      <h3>Ajouter un employé</h3>

      { !isNil(error) &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ error }</p>
        </div>
      }

      <ClientForm { ...{ setFormData } } />

      <div className="uk-margin-medium-top">
        <button className="uk-button uk-button-primary" onClick={ handleSubmit }>Ajouter</button>
      </div>
    </Layout>
  )
}

export default CreateEmployeeScreen