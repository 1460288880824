import React from 'react'
import { getPositionStyles, serieToChartData } from '../../helpers/chart-helpers'
import { ResponsiveLine } from '@nivo/line'

const legendOptions = {
  anchor: 'bottom',
  direction: 'column',
  justify: false,
  translateX: 0,
  translateY: 50,
  itemsSpacing: 0,
  itemDirection: 'left-to-right',
  itemWidth: 80,
  itemHeight: 20,
  itemOpacity: 0.75,
  symbolSize: 12,
  symbolShape: 'circle',
  symbolBorderColor: 'rgba(0, 0, 0, .5)',
}

const LineChart = (props) => {
  const {
    title,
    serie,
    options = {},
  } = props
  const {
    curved = false,
    legend = false
  } = options

  const positionStyles = getPositionStyles(props)

  const chartProps = {
    data: serieToChartData(serie),
    curve: curved ? 'cardinal' : 'linear',
    useMesh: true,
    margin: { top: 20, right: 20, bottom: legend ? 60 : 35, left: 50 },
    legends: legend ? [ legendOptions ] : [],
  }

  return (
    <div className="chart chart--line" style={ positionStyles }>
      <span className="chart__label">{ title }</span>

      <div className="chart__content">
        <ResponsiveLine { ...chartProps } />
      </div>
    </div>
  )
}

export default LineChart
