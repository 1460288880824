import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { filter, isEmpty, defaultTo, join, all, identity, reject, isNil } from 'ramda'
import moment from 'moment'

import Layout from '../../components/layout'
import BookingsTable from '../../bookings/components/bookings-table'

import { summarizeProp, formatCurrency, SERVER_DATE_FORMAT } from '../../helpers'
import useOutsideClick from '../../components/use-outside-click'

import { sliceSelector, itemSelector } from '../state/clients-selectors'
import { fetch, del } from '../state/clients-reducer'
import { sliceSelector as bookingsSliceSelector, forClientSelector as bookingsForClientSelector } from '../../bookings/state/bookings-selectors'
import { fetch as fetchBookings } from '../../bookings/state/bookings-reducer'

import NumberChart from '../../dashboard/components/charts/number-chart'
import DateRangePicker from '../../components/date-range-picker'

const ClientDetailsScreen = (props) => {
  const { match: { params: { clientId } } } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const { all: { isFetching: isFetchingClients, isCached: isClientsCached, error: clientsError } } = useSelector(sliceSelector)
  const client = useSelector(itemSelector(clientId))
  const { all: { isFetching: isFetchingBookings, isCached: isBookingsCached, error: bookingsError } } = useSelector(bookingsSliceSelector)
  const allBookings = useSelector(bookingsForClientSelector(clientId))

  useEffect(() => {
    if (!isClientsCached && !isFetchingClients) dispatch(fetch())
    if (!isBookingsCached && !isFetchingBookings) dispatch(fetchBookings())
  }, [ dispatch, isClientsCached, isFetchingClients, isBookingsCached, isFetchingBookings ])

  const error = join(', ', reject(isNil, [ clientsError, bookingsError ]))
  const isFetching = all(identity, [ isFetchingClients, isFetchingBookings ])
  const isCached = all(identity, [ isClientsCached, isBookingsCached ])

  const hasError = !isEmpty(error) || !client

  const [ showRangeSelector, setShowRangeSelector ] = useState(false)
  const [ dateRange, setDateRange ] = useState({
    startDate: moment().startOf('month').format(SERVER_DATE_FORMAT),
    endDate: moment().endOf('month').format(SERVER_DATE_FORMAT),
  })

  const rangeSelectorRef = useRef()

  useOutsideClick(rangeSelectorRef, () => {
    if (showRangeSelector) setShowRangeSelector(false)
  })

  const previousYearBookings = filter(({ start }) => {
    return moment(start).isBetween(
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year')
    )
  }, allBookings)
  const previousYearRevenue = summarizeProp([ 'invoice', 'amount' ], previousYearBookings)

  const yearBookings = filter(({ start }) => {
    return moment(start).isBetween(
      moment().startOf('year'),
      moment().endOf('year')
    )
  }, allBookings)
  const yearRevenue = summarizeProp([ 'invoice', 'amount' ], yearBookings)

  const nextYearBookings = filter(({ start }) => {
    return moment(start).isBetween(
      moment().add(1, 'year').startOf('year'),
      moment().add(1, 'year').endOf('year')
    )
  }, allBookings)
  const nextYearRevenue = summarizeProp([ 'invoice', 'amount' ], nextYearBookings)

  const [ bookings, setBookings ] = useState([])

  useEffect(() => {
    const filteredBookings = filter(({ start }) => {
      return moment(start).isBetween(
        moment(dateRange.startDate).startOf('day'),
        moment(dateRange.endDate).endOf('day')
      )
    }, allBookings)
    setBookings(filteredBookings)
  }, [ allBookings.length, dateRange ])

  const handleClickEdit = () =>
    history.push(`/clients/${client._id}/edit`)
  const handleClickDelete = () =>
    dispatch(del(client._id, identity, () => history.push('/clients')))

  return (
    <Layout>
      { isFetching && <div data-uk-spinner></div> }
      { !isFetching && hasError &&
        <div className="uk-alert-danger" data-uk-alert>
          <p>{ defaultTo(`Client with id ${clientId} does not exist.`, error) }</p>
        </div>
      }

      { isCached && !hasError &&
        <>
        <div className="uk-flex uk-flex-between">
          <h3>Fiche client</h3>

          <div>
            <button className="uk-button uk-button-secondary" onClick={ handleClickEdit }>Modifier</button>
            <button className="uk-button uk-button-danger" onClick={ handleClickDelete }>Supprimer</button>
          </div>
        </div>

          <div className="uk-margin-medium-top">
            <h4 className="uk-heading-bullet"><span>Informations</span></h4>

            <table className="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td className="uk-text-bold">Entreprise</td>
                  <td className="uk-text-left">{ client.company }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Nom</td>
                  <td className="uk-text-left">{ client.name }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Email</td>
                  <td className="uk-text-left">{ client.email }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Numéro de téléphone</td>
                  <td className="uk-text-left">{ client.phone }</td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Adresse</td>
                  <td className="uk-text-left">
                    { client.address.line &&
                      <>
                        { client.address.line }, { client.address.zip } { client.address.city } ({ client.address.country })
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <td className="uk-text-bold">Information supplémentaire</td>
                  <td className="uk-text-left">{ client.notes }</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="uk-margin-top uk-margin-bottom">
            <h4 className="uk-heading-bullet"><span>Chiffre d'affaires</span></h4>
            <div className="dashboard__charts">
              <NumberChart 
                title="Chiffre d'affaires (année précédente)"
                dimensions={ [] }
                serie={ { total: formatCurrency(previousYearRevenue) } }
                coordinates={ { x: 0, y: 0, w: 4, h: 1 } }
              />
              <NumberChart 
                title="Chiffre d'affaires"
                dimensions={ [] }
                serie={ { total: formatCurrency(yearRevenue) } }
                coordinates={ { x: 4, y: 0, w: 4, h: 1 } }
              />
              <NumberChart 
                title="Chiffre d'affaires (année suivante)"
                dimensions={ [] }
                serie={ { total: formatCurrency(nextYearRevenue) } }
                coordinates={ { x: 8, y: 0, w: 4, h: 1 } }
              />
            </div>
          </div>

          <div className="uk-margin-small-top">
            <h4 className="uk-heading-bullet"><span>Réservations</span></h4>
            <DateRangePicker dateRange={ dateRange } onChange={ setDateRange } />
            <BookingsTable bookings={ bookings } />
          </div>
        </>
      }
    </Layout>  
  )
}

export default ClientDetailsScreen
